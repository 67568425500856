<div *cduxLoading="loadingId; strategy:'overlay'; component:loadingDotsComponent;" [cduxLogClick]="bet.isExpanded ? eventClickType.MY_BETS_COLLAPSE : eventClickType.MY_BETS_EXPAND" (click)="toggleExpandedState()">
    <div class="my-bet" [class.todays-bets-container-overrides]="todaysBetsContainer" [class.first]="index === 0">
        <div class="my-bet__delete-confirmation" [cduxFocus]="bet.isDeleted" [scrollToTop] = 'false' [class.is-showing]="bet.isDeleted" cduxClickStopPropagation>
            <p>Are you sure you want to delete this bet?</p>
            <div class="confirm-button">
                <button class="cancel" (click)="toggleDelete()">No</button>
                <button class="confirmed" (click)="cancelWager()">Yes</button>
            </div>
        </div>

        <div class="my-bet__row error-row" *ngIf="showCancelFailed">
            <div class="bet-error-message">
                We were unable to delete your bet. If you have any questions, please
                <ng-container *ngIf="phoneSupportEnabled; else chatLink">
                    call {{ 'primary-phone-number' | cduxTranslate:affiliateId }}.
                </ng-container>
                <ng-template #chatLink>
                    <a [cduxLogClick]="eventClickType.SUPPORT_CHAT_REQUEST" (click)="launchZeMessenger($event)">CHAT</a> with Player Services.
                </ng-template>
            </div>
        </div>
        <ng-container *ngIf="!todaysBetsContainer || isBetPad">
            <div class="my-bet__title-bar">
                <h2 class="title">
                    <div class="track-name">{{ bet.trackName }}</div>
                    <div class="race-number">Race {{ bet.raceNum }}</div>
                </h2>
                <span class="my-bet__bet-type">{{ bet.poolType | formatWager : bet.runners : bet.delimiter : undefined : bet.quickPick }}</span>
            </div>
            <div class="my-bet__row">
                <span class="my-bet__bet-amount">{{ bet.amount | currency:'USD':'symbol-narrow' }}</span>
                <span class="bets">{{ bet.runners | formatRunners:bet.delimiter }}</span>
            </div>
            <div class="my-bet__row" *ngIf="bet.condWagerData.conditionalWager">
                <ng-container  *ngIf="!!bet.condWagerData.conditionalOdds; else exoticTemplate">
                    <div class="condition">MIN Odds: {{bet.condWagerData.conditionalOdds | odds}}</div>
                    <div class="condition">MAX Odds: {{bet.condWagerData.conditionalMaxOdds | odds}}</div>
                </ng-container>
                <div class="condition">AT MTP: {{bet.condWagerData.conditionalMaxMtp}}</div>
                <ng-template #exoticTemplate>
                    <div class="condition">MIN Probable Payout: {{bet.condWagerData.conditionalProbablePayout | currency:'USD':'symbol':'.0'}}</div>
                    <div class="condition">MAX Probable Payout: {{bet.condWagerData.conditionalMaxProbablePayoff | currency:'USD':'symbol':'.0'}}</div>
                </ng-template>
            </div>
            <div class="my-bet__total-row">
                <span class="total">{{betTotalLabel}}</span>
                <span class="amount">{{ bet.wagerAmount | currency:'USD':'symbol-narrow' }}</span>
                <ng-container *ngIf="bet.betShareData.betShare">
                    <i class="icon--betshare--primary"></i>
                    <span class="shares">{{ bet.betShareData.participantCost | currency:'USD':'symbol-narrow' }}</span>
                </ng-container>
                <div [class.is-open]="bet.isExpanded" class="icon-details-arrow"></div><!-- add is-open class when the bet is clicked -->
            </div>
        </ng-container>
        <ng-container *ngIf="todaysBetsContainer && !isBetPad">
            <ng-container *ngIf="bet.betShareData.betShare">
                <div class="bet-share-cost-container">
                    <i class="icon--betshare--primary"></i>
                </div>
            </ng-container>

            <div class="my-bet__row-3">
                <div class="track-race-container">
                    <span class="track-name">{{ bet.trackName }}</span>
                    <div class="race-number">Race {{ bet.raceNum }}</div>
                </div>

                <div class="bet-type-and-amount-container">
                    <span class="my-bet__bet-amount">{{ bet.amount | currency:'USD':'symbol-narrow' }}</span>
                    <span class="my-bet__bet-type">{{ bet.poolType | formatWager : bet.runners : bet.delimiter : undefined : bet.quickPick }}</span>
                </div>
            </div>
            <div class="my-bet__row-1">
                <div class="bets-container">
                    <cdux-runner-list
                        [runnerList]="bet.runners"
                        [runnerListDelimiter]="bet.delimiter"
                        [collapseBoxedRunners]="false"
                        [poolType]="bet.poolType"
                        [trackType]="bet.trackType"
                    ></cdux-runner-list>
                </div>
            </div>
            <div class="my-bet__row-2" *ngIf="bet.condWagerData.conditionalWager">
                <ng-container  *ngIf="!!bet.condWagerData.conditionalOdds; else exoticTemplate">
                    <div class="condition">MIN Odds: {{bet.condWagerData.conditionalOdds | odds}}</div>
                    <div class="condition">MAX Odds: {{bet.condWagerData.conditionalMaxOdds | odds}}</div>
                </ng-container>
                <div class="condition">AT MTP: {{bet.condWagerData.conditionalMaxMtp}}</div>
                <ng-template #exoticTemplate>
                    <div class="condition">MIN Probable Payout: {{bet.condWagerData.conditionalProbablePayout | currency:'USD':'symbol':'.0'}}</div>
                    <div class="condition">MAX Probable Payout: {{bet.condWagerData.conditionalMaxProbablePayoff | currency:'USD':'symbol':'.0'}}</div>
                </ng-template>
            </div>
            <div class="my-bet__total-row">
                <span class="total">{{betTotalLabel}}</span>
                <div class="amount-details-container">
                    <span class="amount">{{bet.betShareData.betShare ? bet.betShareData.participantCost : bet.wagerAmount | currency:'USD':'symbol-narrow' | negativeSymbol}}</span>
                    <div [class.is-open]="bet.isExpanded" class="icon-details-arrow"></div><!-- add is-open class when the bet is clicked -->
                </div>
            </div>
        </ng-container>
        <div class="my-bet__details is-open is-visible" *ngIf="bet.isExpanded">
            <div class="my-bet__details-row">
                <div class="my-bet__details-label">Status</div>
                <!--Accepted and Open(i.e Conditional Wagers) now share same class-->
                <div class="my-bet__details-data status" [ngClass]="wagerStatusClass">
                    {{ bet.status }}
                </div>
            </div>
            <ng-container *ngIf="bet.betShareData.betShare">
                <div *ngIf="ownedBetSharesLabel" class="my-bet__details-row">
                    <div class="my-bet__details-label">My Shares</div>
                    <div class="my-bet__details-data ">{{ ownedBetSharesLabel }}</div>
                </div>
                <div class="my-bet__details-row">
                    <div class="my-bet__details-label">Cost of Shares</div>
                    <div class="my-bet__details-data ">1 / {{ bet.betShareData.pricePerShare | currency:'USD':'symbol-narrow' }}</div>
                </div>
                <div class="my-bet__details-row">
                    <div class="my-bet__details-label">My Bet Contribution</div>
                    <div class="my-bet__details-data ">{{ bet.betShareData.participantCost | currency:'USD':'symbol-narrow' }} of {{ bet.betShareData.betShareCost | currency:'USD':'symbol-narrow' }}</div>
                </div>
            </ng-container>
            <ng-container *ngIf="!bet.condWagerData.conditionalWager || (bet.condWagerData.conditionalWager && bet.status !== 'Open')">
                <div class="my-bet__details-row">
                    <div class="my-bet__details-label">Transaction ID</div>
                    <div class="my-bet__details-data">{{ bet.serialNumber }}</div>
                </div>
                <div class="my-bet__details-row">
                    <div class="my-bet__details-label">Date</div>
                    <div class="my-bet__details-data">{{ bet.activityDate | date: 'longDate' }}</div>
                </div>
                <div class="my-bet__details-row">
                    <div class="my-bet__details-label">Time</div>
                    <div class="my-bet__details-data">{{ bet.activityDate | date: 'mediumTime'}}/{{ bet.activityDate | timezone }}</div>
                </div>
            </ng-container>
            <div class="my-bet__details-row function-row" *ngIf="this.canShowWagerControls">
                <ng-container *ngIf="bet.betShareData.betShare === bet.betShareData.betShareCaptain">
                    <i class="icon-my-bet delete" *ngIf="bet.raceStatus === raceStatusEnum.OPEN"
                       [cduxLogClick]="eventClickType.MY_BETS_DELETE"
                       (click)="toggleDelete()" cduxClickStopPropagation></i>
                </ng-container>

                <i class="icon-my-bet program" id="active-program-button-{{index+1}}" *ngIf="isProgramAvailable && !isBetPad"
                   [cduxLogClick]="eventClickType.MY_BETS_PROGRAM"
                   (click)="programNavigate(bet)" cduxClickStopPropagation></i>
                <i class="icon-my-bet play" id="active-play-button-{{index+1}}" *ngIf="isVideoToggledOn && isProgramAvailable && !isBetPad"
                   [cduxLogClick]="eventClickType.MY_BETS_VIDEO"
                   (click)="programNavigate(bet, true)" cduxClickStopPropagation></i>
                <ng-container *ngIf="!bet.betShareData.betShare">
                    <i class="icon-my-bet copy" id="active-copy-button-{{index+1}}" *ngIf="bet.raceStatus === raceStatusEnum.OPEN  && isCopyToggledOn"
                       [cduxLogClick]="eventClickType.MY_BETS_COPY"
                       [class.is-success]="isCopySuccess"
                       (click)="!isCopyStarted && copyBet()" cduxClickStopPropagation></i>
                </ng-container>
            </div>
        </div>
        <ng-container *ngIf="bet.betShareData.betShare">
            <!-- DE11627 - This code will work when the defect is resolved in the back end. -->

            <!--<div class="my-bet__row betshare-progress">-->
                <!--<div class="percentage-bar-bkg">-->
                    <!--&lt;!&ndash;fill-percent-xx controls the bar progress&ndash;&gt;-->
                    <!--<div class="percentage-bar-fill fill-percent-{{ betShareProgressPercentage }} top-3"></div>-->
                    <!--<div class="betshare-progress-info">-->
                        <!--<span class="label">Available: </span>-->
                        <!--<span class="data">{{ bet.availableAmount | currency:'USD':'symbol-narrow' }}</span>-->
                        <!--of-->
                        <!--<span class="data">{{ bet.amount | currency:'USD':'symbol-narrow' }}</span>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</div>-->

            <div *ngIf="!showSocialMediaIcons" class="my-bet__row gbl-button-wrap">
                <button type="button" (click)="toggleSocialMedia()" cduxClickStopPropagation>Share Bet</button>
            </div>

            <div *ngIf="showSocialMediaIcons" class="my-bet__row social-media-bet-share">
                <i class="icon--email-small" (click)="share(socialMediaType.EMAIL)" [cduxLogClick]="eventClickType.BETSHARE_EMAIL" cduxClickStopPropagation></i>
                <i *ngIf="!onAndroidTablet" class="icon--messenger-small" (click)="share(socialMediaType.SMS)" [cduxLogClick]="eventClickType.BETSHARE_TEXT" cduxClickStopPropagation></i>
                <i class="icon copy-link">
                    <div class="icon icon--copy-link-small" [style.opacity]="triggerCopy? 0 : 1" [cduxCopyToClipboard]="betShareUrl" (click)="triggerCopy = true" (cduxCopyToClipboardSuccess)="onLinkCopy()" [cduxLogClick]="eventClickType.BETSHARE_COPY" cduxClickStopPropagation></div>
                    <div class="icon-alert-wrapper" [ngClass]="{'is-success':triggerCopy }" cduxClickStopPropagation>
                        <svg class="icon-alert" xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 52 52" >
                            <circle class="icon-alert-circle" cx="26" cy="26" r="26"></circle>
                            <path class="icon-alert-check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"></path>
                        </svg>
                    </div>
                </i>
                <i class="icon--facebook-small" *ngIf="socialMediaToggle" (click)="share(socialMediaType.FACEBOOK)" [cduxLogClick]="eventClickType.BETSHARE_FACEBOOK" cduxClickStopPropagation></i>
                <i class="icon--twitter-small" *ngIf="socialMediaToggle" (click)="share(socialMediaType.TWITTER)" [cduxLogClick]="eventClickType.BETSHARE_TWITTER" cduxClickStopPropagation></i>
            </div>
        </ng-container>
    </div>
</div>
