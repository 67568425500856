<div *cduxLoading="'myBetsOverlay'; strategy:'overlay'; component:loadingDotsComponent;">
    <section class="my-bets-container" [class.todays-bets-container-overrides]="todaysBetsContainer">
        <div *ngIf="todaysBetsContainer && IsLoaded" class="my-bets-header">
            <span>{{ todaysBetsContainer ? 'Active' : 'Pending' }}</span>
        </div>
        <div *ngIf="isError" class="my-bet-empty">
            <div class="waiting-room">
                <h2><i class="icon-exclamation"></i>{{ myBetsErrorMessageHeader }}</h2>
                <p>{{ myBetsErrorMessage }} 
                </p>
                <p>Thank you for your patience!
                </p>
            </div>
        </div>
        <div *ngIf="!showMyBets() && IsLoaded" class="my-bet-empty">
            <p *ngIf="todaysBetsContainer">You have no active bets</p>
            <p *ngIf="!todaysBetsContainer">You have no pending bets</p>
        </div>

        <div *ngIf="showMyBets()">
            <div *ngFor="let bet of myBetsList; let i = index;">
                <cdux-mybets [loadingId]="'betsOverlay-' + i"
                             [bet]="bet"
                             [isVideoToggledOn] = "isVideoToggledOn"
                             [isCopyToggledOn] = "isCopyToggledOn"
                             [isSaveToggledOn] = "isSaveToggledOn"
                             (expand)="toggleExpandedView($event)"
                             (contract)="contractSize()"
                             (cancel)="cancelWager($event)"
                             (openTab)="openBetTab($event)"
                             [index]="i"
                             [programView]="programView"
                             [todaysBetsContainer]="true"
                             id="my-active-bet-{{i+1}}"></cdux-mybets>
            </div>
        </div>
        <div *ngIf="isIVRPhoneMessageToggledOn && IsLoaded && !todaysBetsContainer" class="phone-ivr-message">
            Wagers placed over phone or IVR will not be<br>
            shown until the race is official
        </div>
    </section>
</div>
