import { Component, OnInit, AfterViewInit, OnDestroy, NgZone, Input, Output, EventEmitter } from '@angular/core';
import { CduxRequestError, ENVIRONMENT } from '@cdux/ng-core';
import {
    IAccountFundingInfo,
    IWithdrawBalance,
    IFundingOption,
    ICheckAccountInfo,
    FUND_ID,
    TranslateService,
    FUNDING_OPERATIONS,
    EventClickType,
    FeatureToggleDataService,
    enumFeatureToggle
} from '@cdux/ng-common';
import { Subscription, zip } from 'rxjs';
import { take } from 'rxjs/operators';

import { FundingService } from '../../shared/services/funding.service';

import { FundingCheckService } from '../../shared/services/check.service';
import { CduxSidebarContentComponent } from '../../../sidebar/cdux-sidebar-content-component.class';
import {
    ISidebarComponentProperties, ISidebarHeaderComponent,
    ISidebarPortalComponent,
    ISidebarTitleHeaderConfig
} from '../../../sidebar/interfaces/sidebar-portal-component.interface';
import { FundingPaypalMethodWithdrawComponent } from '../methods/paypal';
import { FundingEzmoneyMethodWithdrawComponent } from '../methods/ezmoney';
import { FundingCheckMethodWithdrawComponent } from '../methods/check';
import { SidebarService } from '../../../sidebar/sidebar.service';
import { SIDEBAR_LOADERS } from '../../../sidebar/enums/loader.enums';
import { MenuItemsEnum } from '../../../menu-items/enums/menu-items.enum';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { enumFundingDisplayStyle } from '../../shared/enums/funding-display-style.enum';
import { ZendeskChatService } from '@cdux/ng-fragments';
import { Router } from '@angular/router';

@Component({
    selector: 'cdux-funding-withdraw-options',
    templateUrl: './withdraw-options.component.html',
    styleUrls: [ './withdraw-options.component.scss' ]
})
export class FundingWithdrawOptionsComponent extends CduxSidebarContentComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input()
    public displayStyle: enumFundingDisplayStyle = enumFundingDisplayStyle.SIDEBAR;

    @Input()
    public selectedMethodId: FUND_ID;

    @Input()
    public isSmallGlass: boolean;

    @Output()
    public selectedMethod: EventEmitter<ISidebarPortalComponent> = new EventEmitter<ISidebarPortalComponent>();

    @Output()
    public methodsLoaded: EventEmitter<IFundingOption[]> = new EventEmitter<IFundingOption[]>();

    @Output()
    public noMethod: EventEmitter<boolean> = new EventEmitter<boolean>();

    public errorCodeArgs: any[];

    public phoneEnabled: boolean;

    public readonly FUND_ID = FUND_ID;

    private readonly _TOTE_DOWN_CODE = 1008;

    private readonly EZ_MONEY_DEPOSIT_PAGE_LINK = '/deposit/ezmoney';

    /**
     *   Current Active Funding Method
     *   Getter to pass value from service to template
     */
    public get activeWithdrawalMethod(): FUND_ID {
        return this._fundingService.activeWithdrawalMethod;
    }

    /**
     *   Collection of Funding Options
     *   Getter to pass value from service to template
     */
    public get fundingOptions(): IFundingOption[] {
        if (this.displayStyle === enumFundingDisplayStyle.SIDEBAR) {
            if (this._fundingService.fundingOptions) {
                return this._fundingService.fundingOptions.filter((option: IFundingOption) => {
                    return (option.fundId === FUND_ID.CHECK_W || !!option.accountInfo);
                });
            } else {
                return [];
            }
        } else {
            return this._fundingService.fundingOptions || [];
        }
    }

    // Expose enum to the template
    public enumFundingDisplayStyle = enumFundingDisplayStyle;
    public return = false;
    public withdrawBalance: IWithdrawBalance;
    public accountInfo: ICheckAccountInfo;
    public methodToSelect: FUND_ID;
    public errorCode: string = null;
    public initialized: boolean = false;
    // Enabled Funding IDs
    public enabledIDs: FUND_ID[] = [];
    public withdrawalOptOutOfferNames: string[] = null;

    private _initSubscription: Subscription;
    private _zoneSub: Subscription;

    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */

    public static getSidebarComponent(): ISidebarPortalComponent {
        return {
            component: FundingWithdrawOptionsComponent,
            properties: {
                navTarget: MenuItemsEnum.FUNDING
            }
        };
    }

    public static getHeaderComponent(): ISidebarTitleHeaderConfig {
        return {
            title: 'Withdraw Funds'
        };
    }

    public setProperties(properties: ISidebarComponentProperties) {
        this.return = (properties.isReturningFromChild === true);
    }

    /* END CduxSidebarContentComponent
     * =============================== */


    constructor(private _zone: NgZone,
                private _sidebarService: SidebarService,
                private _translateService: TranslateService,
                private _fundingService: FundingService,
                private _checkService: FundingCheckService,
                private _eventTrackingService: EventTrackingService,
                private _featureToggleService: FeatureToggleDataService,
                private _zendeskChatService: ZendeskChatService,
                private _router: Router,
                private _environment: ENVIRONMENT) {
        super();
    }

    public ngOnInit() {
        this.phoneEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.PHONE_SUPPORT);
        this._fundingService.setEnabledFundingMethods();
        this._fundingService.clearFundingMethods();
        this._sidebarService.showLoadingOverlay(SIDEBAR_LOADERS.DOT_LOADER);
    }

    public ngAfterViewInit() {

        this._zoneSub = this._zone.onStable.pipe(
            take(1)
        ).subscribe(() => {
            this._initSubscription = zip(
                this._checkService.getAccountInfo(),
                this._fundingService.requestFundingMethodData(FUNDING_OPERATIONS.WITHDRAW, this.enabledIDs),
                this._fundingService.getWithdrawBalance(),
                this._fundingService.getWithdrawalOptOutList(),

                (accountInfo: ICheckAccountInfo,
                 fundingMethodData: IAccountFundingInfo,
                 withdrawBalance: IWithdrawBalance,
                 optOutBonuses: string[]) => (
                    {
                        accountInfo,
                        fundingMethodData,
                        withdrawBalance,
                        optOutBonuses
                    }
                )
            ).subscribe(
                (results: any) => {
                    this._initSubscription.unsubscribe();
                    this._initSubscription = null;
                    this.initialized = true;

                    this.withdrawBalance = results.withdrawBalance;
                    this.accountInfo = results.accountInfo;
                    this._sidebarService.hideLoadingOverlay(SIDEBAR_LOADERS.DOT_LOADER);
                    if (!this.return && this.methodToSelect) {
                        const method = this.fundingOptions.find((v) => v.fundId === this.activeWithdrawalMethod);
                        if (method.fundId === this.methodToSelect || (method && method.accountInfo && !method.locked)) {
                            this.selectFundingMethod(method);
                        }
                    } else if (!this.isSmallGlass && this.displayStyle !== enumFundingDisplayStyle.SIDEBAR) {
                        this.selectFundingMethod(this.fundingOptions[0]);
                    }
                    if (results.optOutBonuses.length > 0) {
                        this.errorCode = 'offer-optout-warning';
                        this.withdrawalOptOutOfferNames = results.optOutBonuses;
                    }
                },
                (error: CduxRequestError) => {
                    this._sidebarService.hideLoadingOverlay(SIDEBAR_LOADERS.DOT_LOADER);
                    if (error.data.errorCode) {
                        if (error.data.errorCode === this._TOTE_DOWN_CODE) {
                            this.errorCode = FUNDING_OPERATIONS.WITHDRAW + '-' + error.data.errorCode;
                        } else {
                            this.errorCode = error.data.errorCode;
                        }
                    } else {
                        // If an unknown error occurs mimic Tote Down message
                        this.errorCode = FUNDING_OPERATIONS.WITHDRAW + '-' + this._TOTE_DOWN_CODE;
                    }
                }
            );
        });
    }

    public ngOnDestroy() {
        if (this._zoneSub) {
            this._zoneSub.unsubscribe();
        }
        if (this._initSubscription) {
            this._initSubscription.unsubscribe();
            this._initSubscription = null;
        }
    }

    /**
     * Checks to see if we have added translations for this funding option
     */
    public canDisplayFundingOption(method: IFundingOption) {
        return this._translateService.hasLanguage(method.fundType);
    }


    /**
     * Retrieves the Specific Tile Icon to use for the Funding Method
     */
    public getIcon(key: string, method: IFundingOption) {
        return this._translateService.translate(key, method.fundType);
    }

    /**
     * Opens the Withdraw Option Details Page in the SideNav
     * @param method - Funding Method to Display
     */
    public selectFundingMethod(method: IFundingOption) {
        if (method.locked) {
            this.errorCodeArgs = [this._translateService.translate('primary-phone-number', this._environment.affiliateId.toString(), false)]
            this.errorCode = this.phoneEnabled ? 'locked-withdraw' : 'locked-withdraw-chat';
            return;
        }
        let portalComponent: ISidebarPortalComponent;
        let headerComponent: ISidebarHeaderComponent;
        let withdrawEvent: EventClickType;
        const componentProperties = {
            fundingMethodDetails: method,
            withdrawBalance: this.withdrawBalance,
            accountInfo: this.accountInfo
        }

        switch (method.fundId) {
            case FUND_ID.CHECK_W:
                if (this.displayStyle === enumFundingDisplayStyle.SIDEBAR) {
                    portalComponent = FundingCheckMethodWithdrawComponent.getSidebarComponent({fundingMethodDetails: method, withdrawBalance: this.withdrawBalance, accountInfo: this.accountInfo});
                    headerComponent = FundingCheckMethodWithdrawComponent.getHeaderComponent();
                    withdrawEvent = EventClickType.CHECK_WITHDRAW_TILE;
                } else {
                    this.selectedMethod.emit(FundingCheckMethodWithdrawComponent.getSidebarComponent(componentProperties));
                }
                break;
            case FUND_ID.EZMONEY_W:
                if (this.displayStyle === enumFundingDisplayStyle.SIDEBAR) {
                    portalComponent = FundingEzmoneyMethodWithdrawComponent.getSidebarComponent({fundingMethodDetails: method, withdrawBalance: this.withdrawBalance, accountInfo: this.accountInfo});
                    headerComponent = FundingEzmoneyMethodWithdrawComponent.getHeaderComponent();
                    withdrawEvent = EventClickType.EZ_MONEY_WITHDRAW_TILE;
                } else {
                    this.selectedMethod.emit(FundingEzmoneyMethodWithdrawComponent.getSidebarComponent(componentProperties));
                }
                break;
            case FUND_ID.PAYPAL_W:
                if (this.displayStyle === enumFundingDisplayStyle.SIDEBAR) {
                    portalComponent = FundingPaypalMethodWithdrawComponent.getSidebarComponent({fundingMethodDetails: method, withdrawBalance: this.withdrawBalance, accountInfo: this.accountInfo});
                    headerComponent = FundingPaypalMethodWithdrawComponent.getHeaderComponent();
                    withdrawEvent = EventClickType.PAYPAL_WITHDRAW_TILE;
                } else {
                    this.selectedMethod.emit(FundingEzmoneyMethodWithdrawComponent.getSidebarComponent(componentProperties));
                }
                break;
            default:
                return;
        }

        this._sidebarService.loadComponent(portalComponent, headerComponent);
        this._eventTrackingService.logClickEvent(withdrawEvent);
    }

    public launchZeMessenger() {
        if (!this.phoneEnabled && this._zendeskChatService.isLoaded()) {
            this._zendeskChatService.setMessengerVisibility(true);
            this._zendeskChatService.openZeMessenger();
        }
    }

    public navToEzMoneyDeposit() {
        this._fundingService.postDepositRedirectURL = this._router.url.split('?')[0];
        this._router.navigate([this.EZ_MONEY_DEPOSIT_PAGE_LINK]);
    }
}

