import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PortalModule } from '@angular/cdk/portal';
import { CduxNgCommonModule, EventSessionModule } from '@cdux/ng-common';
import { CduxMediaModule } from '@cdux/ng-platform/web';
import { FormatRunnersPipe, SharedModule as SharedFragmentsModule } from '@cdux/ng-fragments';

import {
    CduxCopyToClipboardDirective,
    CduxExternalLinkDirective,
    CduxHoverDirective,
    CduxFocusDirective,
    ClickAutomaticBlurDirective,
    ClickStopPropagationDirective,
    LineClampDirective,
    RestrictNumericDirective,
    VisibleDirective,
    WaypointDirective, CduxDisplayModesDirective,
} from './directives';

import {
    AuthGuard,
    FeatureToggleGuard,
    DeviceRouteGuard,
    GroupRouteGuard,
    PlatformGuard,
    ViewableWagerDataGuard
} from './guards';

import { FormatAgeSexRestrictionsPipe, FormatDistancePipe, FormatSurfacePipe, SharedResourcePipe } from './pipes';

import {
    ChatComponent,
    FavoriteTrackComponent,
    RunnerListComponent,
    TrackGroupComponent
} from './components';

import { WaypointService } from './services/waypoint/waypoint.service';

@NgModule({
    declarations: [
        CduxDisplayModesDirective,
        CduxCopyToClipboardDirective,
        CduxExternalLinkDirective,
        CduxHoverDirective,
        CduxFocusDirective,
        ChatComponent,
        ClickAutomaticBlurDirective,
        ClickStopPropagationDirective,
        FavoriteTrackComponent,
        FormatAgeSexRestrictionsPipe,
        FormatDistancePipe,
        FormatSurfacePipe,
        LineClampDirective,
        RestrictNumericDirective,
        RunnerListComponent,
        SharedResourcePipe,
        TrackGroupComponent,
        VisibleDirective,
        WaypointDirective,
        // DisplayModesDirective
    ],
    imports: [
        CommonModule,
        CduxNgCommonModule,
        CduxMediaModule,
        EventSessionModule,
        PortalModule,
        SharedFragmentsModule
    ],
    exports: [
        CduxDisplayModesDirective,
        CduxCopyToClipboardDirective,
        CduxExternalLinkDirective,
        CduxHoverDirective,
        CduxFocusDirective,
        ClickAutomaticBlurDirective,
        ClickStopPropagationDirective,
        ChatComponent,
        FavoriteTrackComponent,
        FormatAgeSexRestrictionsPipe,
        FormatDistancePipe,
        FormatSurfacePipe,
        LineClampDirective,
        PortalModule,
        RestrictNumericDirective,
        RunnerListComponent,
        SharedResourcePipe,
        TrackGroupComponent,
        VisibleDirective,
        WaypointDirective,
        // DisplayModesDirective
    ]
})
export class TuxCommonModule {
    // For Consistency, This Method is Present on All Modules, Even
    // Those Without Providers
    public static forRoot(): ModuleWithProviders<TuxCommonModule> {
        return {
            ngModule: TuxCommonModule,
            providers: [
                AuthGuard,
                FeatureToggleGuard,
                FormatAgeSexRestrictionsPipe,
                FormatDistancePipe,
                FormatRunnersPipe,
                FormatSurfacePipe,
                DeviceRouteGuard,
                GroupRouteGuard,
                PlatformGuard,
                ViewableWagerDataGuard,
                WaypointService,
            ]
        }
    }
}
