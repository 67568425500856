import { Component, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { FeatureToggleDataService, enumFeatureToggle } from '@cdux/ng-common';
import {
    LoadingService,
    LoadingDotsComponent,
    IWager,
    StatusCodes,
    ErrorReasons
} from '@cdux/ng-fragments';

import { MyBetsBusinessService } from '../../services/my-bets.business.service';
import { BetsBusinessService } from '../../../bet-slip/services/bets.business.service';
import { BetsCommonService } from '../../services/bets-common.service';
import { MyBetsComponent } from '../my-bets/my-bets.component';
import { BetsViewEnum } from '../../../bets/components/bets-container/bets-container.component';
import { enumProgramViews } from 'app/shared/program/enums/program-views.enum';

@Component({
    selector: 'cdux-my-bets-container',
    templateUrl: './my-bets-container.component.html',
    styleUrls: ['./my-bets-container.component.scss']
})
export class MyBetsContainerComponent implements OnInit, OnDestroy {
    @Output() public contract: EventEmitter<undefined> = new EventEmitter<undefined>();
    @Output() public openTab: EventEmitter<BetsViewEnum> = new EventEmitter<BetsViewEnum>();
    @Output() public initialized: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() public programView: enumProgramViews;
    @Input() public todaysBetsContainer: boolean = false;

    public  readonly succesMessage = 'Success';
    public isVideoToggledOn = false;
    public isCopyToggledOn = false;
    public isSaveToggledOn = false;
    public isIVRPhoneMessageToggledOn = false;

    // Collection of Subscriptions to be Cleaned
    private _subscription: Subscription = new Subscription();
    public myBetsList: IWager[] = [];
    public IsLoaded = false;
    public loadingDotsComponent = LoadingDotsComponent;
    public isError = false;
    public myBetsErrorMessage: string;
    public myBetsErrorMessageHeader: string;

    constructor(
        private myBetsService: MyBetsBusinessService,
        private betsService: BetsBusinessService,
        private betsCommonService: BetsCommonService,
        private _loadingService: LoadingService,
        private featureToggleService: FeatureToggleDataService
    ) { }

    public ngOnInit() {
        this._loadingService.register('myBetsOverlay');
        // Initialize the common dataservice
        this.betsCommonService.initializeCommonService().pipe(
            take(1)
        ).subscribe(data => {
            // Initial fetch data
            this._subscription = this.getBets();
        });
        this.isVideoToggledOn = this.featureToggleService.isFeatureToggleOn(enumFeatureToggle.BET_VIDEO);
        this.isCopyToggledOn = this.featureToggleService.isFeatureToggleOn(enumFeatureToggle.MY_BETS_COPY);
        this.isSaveToggledOn = this.featureToggleService.isFeatureToggleOn(enumFeatureToggle.SAVED_BETS);
        this.isIVRPhoneMessageToggledOn = this.featureToggleService.isFeatureToggleOn(enumFeatureToggle.IVR_WAGER_MSG);
    }

    private getBets(): Subscription {
        return this.myBetsService.getMyBets().subscribe((data: IWager[]) => {
            /*
             Overlay will resolve after a minimum of one animation cycle.
             Set value slightly lower than view initialization so animation does not appear over text
             */
            this._loadingService.resolve('myBetsOverlay', 900, 'success');

            setTimeout(() => {
                this.initialized.emit(true);
                this.myBetsList = data;
                // Removing the loading CSS
                this.IsLoaded = true;
                this.contractSize();
            }, 1000);
        }, (response) => {
                // look at data object of error to determine if this is a waiting room response, if so show waiting room
                this._loadingService.resolve('myBetsOverlay', -1);
                this.initialized.emit(true);
                this.isError = true;
                if (response.data && response.data.status === StatusCodes.WAITING_ROOM_DISPLAY_CODE) {  // agreed upon status code with prod engineering
                    this.myBetsErrorMessage = ErrorReasons.WAITING_ROOM;
                    this.myBetsErrorMessageHeader = ErrorReasons.WAITING_ROOM_HEADER;
                } else {
                    this.myBetsErrorMessage = ErrorReasons.ERROR_MESSAGE;
                    this.myBetsErrorMessageHeader = ErrorReasons.ERROR_MESSAGE_HEADER;
                }
            });
    }

    /**
     * Communicate upwards that a transaction's size has contracted.
     */
    public contractSize() {
        this.contract.emit();
    }

    public openBetTab(type: BetsViewEnum) {
        this.openTab.emit(type);
    }

    public toggleExpandedView(bet: IWager): void {
        const allMyBets = this.myBetsList;
        this.closeExpandedBet(allMyBets, bet);
    }

    public showMyBets(): boolean {
        return !!this.myBetsList.length;
    }

    /**
     * Find the expanded/deleting bet and collapse it as other bet is expanded
     * @param {IWager[]} - List of all bets
     * @param {IWager} - Bet to remain in expanded state
     */
    private closeExpandedBet(betList: IWager[], nextBet: IWager): void {
        // There is at most one bet expanded/deleting at a time
        betList.filter(bet => bet.isExpanded === true || bet.isDeleted === true).forEach(bet => {
            bet.isExpanded = false;
            bet.isDeleted = false;
        });

        nextBet.isExpanded = true;
    }

    public cancelWager(betComponent: MyBetsComponent): Promise<boolean> {
        return this.betsService.cancelWager(betComponent.bet).then((success) => {
            if (success) {
                betComponent.resolveLoader(900).then(() => {
                    betComponent.isCanceling = false;
                });

                const index: number = this.myBetsList.indexOf(betComponent.bet);
                if (index !== -1) {
                    this.myBetsList.splice(index, 1);
                }

                return true;
            } else {
                throw new Error('Failed to cancel Wager.');
            }
        }).catch(() => {
            betComponent.resolveLoader(900).then(() => {
                betComponent.isCanceling = false;
            });
            betComponent.showCancelFailure();
            return false;
        });
    }

    public ngOnDestroy() {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }
}
