import { Injectable } from '@angular/core';
import {
    ToteDataService,
    WagerDataService,
    JwtSessionService,
    CduxDateUtil,
} from '@cdux/ng-common';
import { IWager, WagerStatus, WagerDisplayStatus } from '@cdux/ng-fragments';
import { combineLatest, Observable, of } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { WagerFactoryService } from './wager.factory.service';

import { CduxCacheService } from '@cdux/ng-platform/web';

@Injectable()
export class MyBetsBusinessService {
    private static CACHE_PREFIX = 'cdux-mybets-';

    private _betStatusesDisplayed = [
        WagerDisplayStatus.OPEN,
        WagerDisplayStatus.ACCEPTED
    ];

    constructor(
        private sessionService: JwtSessionService,
        private toteDataService: ToteDataService,
        private cacheService: CduxCacheService,
        private wagerService: WagerDataService,
        private wagerFactory: WagerFactoryService
    ) {
        // clear local bets cache whenever the user logs out of a session
        sessionService.addLogoutTask(() => this.flushMyBetsCache().catch(() => null), true);
    }

    /**
     * will fetch data fom the service
     * @returns {Observable<IWager[]>}
     */
    public getMyBets(showAllBets = false): Observable<IWager[]> {
        return this.wagerService.todaysWagers(true).pipe(
            map(bets => // convert and sort all wagers
                !bets ? [] : bets
                    .map(bet => this.wagerFactory.createActiveWager(bet))
                    .sort((a: IWager, b: IWager) => b.activityDate.getTime() - a.activityDate.getTime())
            ),
            // update local bets cache whenever we receive new data from service
            tap(bets => this.cacheService.updateCache(this.getCacheKey(), bets)),
            map(bets => // filter by wager status
                showAllBets ? bets : bets.filter(bet => this.shouldDisplayBet(bet))
            )
        );
    }

    public getMyBetsCache(showAllBets = false, ttl?: number): Observable<IWager[]> {
        return combineLatest([
            this.sessionService.onAuthenticationChange.pipe(
                startWith(this.sessionService.isLoggedIn())
            ),
            this.toteDataService.currentRaceDate(true).pipe(
                distinctUntilChanged()
            )
        ]).pipe(
            switchMap(([isLoggedIn]) => {
                if (isLoggedIn === false) {
                    return of(<IWager[]> []);
                }
                return this.cacheService.observeCache<IWager[]>(
                    this.getCacheKey(),
                    this.getMyBets(true), // cache all
                    isNaN(ttl) ? this.getCacheTTL() : ttl
                ).pipe(
                    map(cache => // filter by wager status
                        !cache ? [] : showAllBets ? cache : cache.filter(bet => this.shouldDisplayBet(bet))
                    )
                )
            })
        );
    }

    public async flushMyBetsCache(): Promise<IWager[]> {
        return this.cacheService.flushCache<IWager[]>(this.getCacheKey())
            .then((wagers) => wagers, (error) => []);
    }

    public async refreshMyBetsCache(showAllBets = false): Promise<IWager[]> {
        // calling getMyBets to update cache
        return this.getMyBets(showAllBets).toPromise();
    }

    private getCacheKey(userInfo = this.sessionService.getUserInfo()): string {
        return MyBetsBusinessService.CACHE_PREFIX +
            (userInfo && userInfo.username || 'anonymous');
    }

    private getCacheTTL(): number {
        // get number of milliseconds since midnight
        return Date.now() - CduxDateUtil.floorDate().getTime();
    }

    private shouldDisplayBet(bet: IWager): boolean {
        return bet.status && this._betStatusesDisplayed.includes(bet.status as WagerDisplayStatus)
            && ![WagerStatus.CANCELLED, WagerStatus.CANCELLING].includes((bet.betShareData.betShareStatusId || '').toLowerCase() as WagerStatus);
    }
}
