import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, startWith, switchMap, take, tap } from 'rxjs/operators';

import {
    ICompletedBets,
    IGetDataResponse,
    IGetMyBetsResponse,
    ToteDataService,
    WagerDataService,
    JwtSessionService,
    CduxDateUtil,
} from '@cdux/ng-common';
import { CduxCacheService } from '@cdux/ng-platform/web';
import { IWager, WagerStatus, BetShareUtilService } from '@cdux/ng-fragments';

import { BetsCommonService } from './bets-common.service';
import { WagerFactoryService } from './wager.factory.service';

const DATE_FROZEN_WAGERS = new Date('9999-12-01T23:59:59');

export class BetListBetsModel {
    public bets: IWager[];
    public date: Date;
    public isToday: boolean = false;
    public isFrozen = false;

    /**
     * shortcut used for sorting
     */
    public timestamp: number;

    private static zeroTime (dateObj: Date) {
        dateObj = new Date(dateObj.valueOf()); // copy
        /*
         * Because we're not using UTC elsewhere
         * (namely, in isSameDate), we mustn't use it here.
         */
        dateObj.setHours(0, 0, 0, 0);

        return dateObj;
    }

    constructor (bet: IWager) {
        if (bet.isFrozen) {
            // set the date in the future so it sorts to the top
            this.date = DATE_FROZEN_WAGERS;
            this.isFrozen = true;
        } else {
            this.date = BetListBetsModel.zeroTime(bet.activityDate);
        }
        this.isToday = this.date.toDateString() === new Date().toDateString();
        this.timestamp = this.date.getTime();
        this.bets = [bet];
    }
}

@Injectable()
export class CompletedBetsBusinessService {
    private static DATE_ONE_WEEK_AGO = CompletedBetsBusinessService.getDateForDaysAgo(7);
    private static CACHE_PREFIX = 'cdux-completedbets-';

    public static isSameDate(bet1: Date, bet2: Date): boolean {
        return bet1.toDateString() === bet2.toDateString();
    }

    /**
     * returns a date in the past, determined by the number of days ago
     *
     * @param days
     * @returns {Date}
     */
    private static getDateForDaysAgo (days: number): Date {
        return new Date(new Date().getTime() - (days * 24 * 60 * 60 * 1000));
    }

    constructor(
        private betsCommonService: BetsCommonService,
        private cacheService: CduxCacheService,
        private sessionService: JwtSessionService,
        private toteDataService: ToteDataService,
        private wagerService: WagerDataService,
        private _wagerFactory: WagerFactoryService,
        private _betShareUtil: BetShareUtilService
    ) {
        // clear local bets cache whenever the user logs out of a session
        sessionService.addLogoutTask(() => this.flushTodaysCompletedBetsCache().catch(() => null), true);

        this.betsCommonService.initializeCommonService().pipe(
            take(1)
        ).subscribe();
    }

    /**
     * returns data structure containing all completed bets for user
     *
     * Wagers are grouped by date. The groups are sorted and sorted internally.
     * Frozen wagers will appear at the latest date (DATE_FROZEN_WAGERS).
     *
     * @returns {Observable<BetListBetsModel[]>}
     */
    public getCompletedBets (): Observable<BetListBetsModel[]> {
        return this.wagerService.completedBets(this.getFromDate(), this.getToDate()).pipe(
            map((data: ICompletedBets) => {
                let models = [];
                models = models.concat(
                    this.createTodaysBetsModels(data.todaysWagers),
                    this.createPastCompletedBetsModels(data.previousWagers)
                );

                return this.createBetListBetsModel(models);
            })
        );
    }

    public getTodaysCompletedBets (): Observable<IWager[]> {
        return this.toteDataService.currentRaceDate(true).pipe(
            distinctUntilChanged(),
            switchMap(raceDate => this.wagerService.completedBets(raceDate, raceDate)),
            map(completedBets => this.createTodaysBetsModels(completedBets.todaysWagers)),
            // update local bets cache whenever we receive new data from service
            tap(bets => this.cacheService.updateCache(this.getCacheKey(), bets))
        );
    }

    public getTodaysCompletedBetsCache (ttl?: number): Observable<IWager[]> {
        return combineLatest([
            this.sessionService.onAuthenticationChange.pipe(
                startWith(this.sessionService.isLoggedIn())
            ),
            this.toteDataService.currentRaceDate(true).pipe(
                distinctUntilChanged()
            )
        ]).pipe(
            switchMap(([isLoggedIn]) => {
                if (isLoggedIn === false) {
                    return [];
                }
                return this.cacheService.observeCache<IWager[]>(
                    this.getCacheKey(),
                    this.getTodaysCompletedBets(),
                    isNaN(ttl) ? this.getCacheTTL() : ttl
                ).pipe(map(cache => cache ? cache : []))
            })
        );
    }

    public async flushTodaysCompletedBetsCache (): Promise<IWager[]> {
        return this.cacheService.flushCache<IWager[]>(this.getCacheKey());
    }

    public async refreshTodaysCompletedBetsCache (): Promise<IWager[]> {
        // calling getTodaysCompletedBets to update cache
        return this.getTodaysCompletedBets().toPromise();
    }

    /*
     * standardizes today's wagers (from tote) into IWager interface
     * This method is public primarily for use by unit tests.
     * @param {IGetDataResponse[]} bets
     * @returns {IWager[]}
     */
    public createTodaysBetsModels (bets: IGetDataResponse[]): IWager[] {
        const betModels = [];

        // filter down to completed and canceled non-conditional wagers
        const filteredBets = bets.filter(bet => {
            const betStatus = bet.status && bet.status.toLowerCase();
            return (betStatus === WagerStatus.PAID || betStatus === WagerStatus.CANCELED || betStatus === WagerStatus.CANCELLED
                || (bet.betShare && this._betShareUtil.isBetShareCancelled(bet.betShareStatusId)) || betStatus === WagerStatus.TRIGGERED || betStatus === WagerStatus.EXPIRED);
        });

        filteredBets.forEach(bet => {
            betModels.push(this._wagerFactory.createTodaysCompletedWager(bet));
        });

        return betModels;
    }

    /**
     * standardizes completed wagers (from acct history) into IWager interface
     * This method is public primarily for use by unit tests.
     * @param {IGetMyBetsResponse[]} bets
     * @returns {IWager[]}
     */
    public createPastCompletedBetsModels (bets: IGetMyBetsResponse[]): IWager[] {
        const betModels = [];

        bets.forEach(bet => {
            betModels.push(this._wagerFactory.createPastCompletedWager(bet));
        });

        return betModels;
    }

    public createBetListBetsModel(models: IWager[]): BetListBetsModel[] {
        // group wagers by date in BetListModels
        const completedBetsList = [];
        let frozenIndex = -1;
        while (models.length > 0) {
            const bet = models.shift();

            if (bet.isFrozen) {
                /*
                * We can't go by wager dates to group frozen wagers,
                * so we'll have to store the index when the first
                * is added to completedBetsList.
                */
                if (frozenIndex === -1) {
                    frozenIndex = completedBetsList.push(new BetListBetsModel(bet));
                    frozenIndex--;
                } else {
                    completedBetsList[frozenIndex].bets.push(bet);
                }
            } else {
                /*
                * betListObj will be the first element of
                * completedBetsList with a date matching that of the
                * bet object from models. There should be only one
                * match, a BetListBetsModel instance which will have a bets
                * property with a list of all bets on that date.
                *
                * If there's no match (i.e., no wagers for that date in
                * completedBetsList), betListObj will be undefined and
                * a new BetListBetsModel instance will be instantiated and
                * added to completedBetsList.
                */
                const betListObj = completedBetsList.find(element => CompletedBetsBusinessService.isSameDate(bet.activityDate, element.date));
                if (betListObj) {
                    betListObj.bets.push(bet);
                } else {
                    completedBetsList.push(new BetListBetsModel(bet));
                }
            }
        }

        // sort date groups
        completedBetsList.sort((a: BetListBetsModel, b: BetListBetsModel) => {
            return b.timestamp - a.timestamp;
        });

        // sort within groups
        for (const betList of completedBetsList) {
            betList.bets.sort((bet1: IWager, bet2: IWager) => {
                return bet2.activityDate.getTime() - bet1.activityDate.getTime();
            });
        }

        return completedBetsList;
    }

    /**
     * returns date in format expected for web service calls yyyy-mm-dd
     * @returns {string}
     */
    private getToDate(): string {
        const date = new Date();
        const year = date.getFullYear();
        const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
        const month = (date.getMonth() + 1) > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
        return year + '-' + month + '-' + day;
    }

    /**
     * returns date a week prior to current date
     * @returns {string}
     */
    private getFromDate(): string {
        const last = CompletedBetsBusinessService.DATE_ONE_WEEK_AGO;
        const day = last.getDate() > 9 ? last.getDate() : '0' + last.getDate();
        const month = (last.getMonth() + 1) > 9 ? last.getMonth() + 1 : '0' + (last.getMonth() + 1);
        const year = last.getFullYear();
        return year + '-' + month + '-' + day;
    }

    private getCacheKey(userInfo = this.sessionService.getUserInfo()): string {
        return CompletedBetsBusinessService.CACHE_PREFIX +
            (userInfo && userInfo.username || 'anonymous');
    }

    private getCacheTTL(): number {
        // get number of milliseconds since midnight
        return Date.now() - CduxDateUtil.floorDate().getTime();
    }
}
