import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CurrencyPipe } from '@angular/common';


import { ENVIRONMENT } from '@cdux/ng-core';
import { FeatureToggleDataService, JwtSessionService, TranslateService } from '@cdux/ng-common';

import { FundingService } from 'app/shared/funding/shared/services/funding.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service'

import { FundingAbstractMethodWithdrawComponent } from 'app/shared/funding/components/methods/abstract-method-withdraw.component';
import { FundingEzmoneyService } from 'app/shared/funding/shared/services/ezmoney.service';
import {
    ISidebarComponentProperties,
    ISidebarPortalComponent
} from 'app/shared/sidebar/interfaces/sidebar-portal-component.interface';
import { FundingEzMoneyMethodHeaderComponent } from './ezmoney-method-header.component';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { SIDEBAR_LOADERS } from 'app/shared/sidebar/enums/loader.enums';
import { IFundingMethodComponentProperties } from 'app/shared/funding/shared/interfaces/funding-sidebar-component.interfaces';
import { enumWithdrawOptions } from 'app/shared/funding/components/methods/abstract-method.component';
import { HeaderService } from 'app/shared/header/services/header.service';
import { FullPageFundingConstants } from 'app/shared/funding/full-page-funding/full-page-funding.constants';
import { enumFundingDisplayStyle } from 'app/shared/funding/shared/enums/funding-display-style.enum';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';

@Component({
    selector: 'cdux-funding-ezmoney-method-withdraw',
    templateUrl: './ezmoney-method-withdraw.component.html'
})
export class FundingEzmoneyMethodWithdrawComponent extends FundingAbstractMethodWithdrawComponent implements OnInit {

    /* IMPLEMENT CduxSidebarContentComponent
     * ===================================== */

    public static getSidebarComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingEzmoneyMethodWithdrawComponent, options)
    }

    public static getComponent(options: IFundingMethodComponentProperties): ISidebarPortalComponent {
        return super.createSidebarPortal(FundingEzmoneyMethodWithdrawComponent, options)
    }

    public static getHeaderComponent(): ISidebarPortalComponent {
        return FundingEzMoneyMethodHeaderComponent.getSidebarComponent();
    }

    public setProperties(properties: ISidebarComponentProperties) {
        super.setProperties(properties);
    }

    /* END CduxSidebarContentComponent
     * =============================== */

    public fpwEnabled: boolean = false;
    public enumWithdrawDisplayStyle = enumFundingDisplayStyle;

    constructor(_environment: ENVIRONMENT,
                _fb: UntypedFormBuilder,
                _fundingService: FundingService,
                _sidebarService: SidebarService,
                _eventTrackingService: EventTrackingService,
                _translateService: TranslateService,
                _cdr: ChangeDetectorRef,
                _mediaService: CduxMediaToggleService,
                public router: Router,
                _headerService: HeaderService,
                protected _sessionService: JwtSessionService,
                private _ezMoneyService: FundingEzmoneyService,
                private _currencyPipe: CurrencyPipe,
                _ftService: FeatureToggleDataService, ) {
        super(
            _environment,
            _fb,
            _fundingService,
            _sidebarService,
            _eventTrackingService,
            _translateService,
            router,
            _headerService,
            _mediaService,
            _sessionService,
            _ftService,
            _cdr,
        );
        this.operationMethod = enumWithdrawOptions.EZ_MONEY;
    }

    public ngOnInit() {
        this.fpwEnabled = this._featureToggleService.isFeatureToggleOn(FullPageFundingConstants.FULL_PAGE_WITHDRAWAL_FT);
        this._initForm([
            {
                name: 'amount',
                default: 0,
                validators: [
                    Validators.min(1),
                    Validators.max(this.getMaxWithdrawAmount()),
                    Validators.pattern(this._VALID_AMOUNT_PATTERN)
                ]
            }
        ]);
    }

    /**
     * Triggers a withdraw in the Business Service
     */
    public onWithdraw() {
        this.pendingWithdraw = true;
        const amount = this.form.get('amount').value;
        this._sidebarService.showLoadingOverlay(SIDEBAR_LOADERS.SPINNING_LOADER);
        this.successMessage = `${this._currencyPipe.transform(amount, 'USD', 'symbol-narrow')} has been withdrawn from your balance to your EZMoney account ending in ${this.fundingMethodDetails.accountInfo}.`;
        this._ezMoneyService.withdraw(amount).subscribe((res) => {
            this._handleResponse(
                res,
                this.successMessage,
                amount
            );
        });
    }


}
