import { BetSlipErrorsService } from 'app/shared/bet-slip/services/bet-slip-errors.service';
import { Component, ChangeDetectionStrategy, ChangeDetectorRef, Input } from '@angular/core';
import { ENVIRONMENT } from '@cdux/ng-core';
import {
    BetCalculatorBusinessService,
    TranslateService,
    IBetResult,
    StringSlugifyPipe,
    enumRaceStatus,
    TrackService,
    ToteDataService,
    WagerCalculatorService,
    WagerValidationService,
    FeatureToggleDataService,
    JwtSessionService,
    enumFeatureToggle,
    enumTrackType,
    WagerState,
    ITrackBasic,
} from '@cdux/ng-common';
import { AbstractBetTotalComponent } from 'app/shared/bet-slip/components/bet-total/abstract-bet-total.component';
import { BetSlipBusinessService } from 'app/shared/bet-slip/services/bet-slip.business.service';
import { BetShareBusinessService } from 'app/shared/bet-slip/services/betshare.business.service';
import { BetsBusinessService } from 'app/shared/bet-slip/services/bets.business.service';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { Router } from '@angular/router';
import { ConditionalWageringBusinessService } from 'app/shared/bet-slip/services/conditional-wagering.business.service';
import { BET_ERROR_CODES } from 'app/shared/bet-slip/enums/bet-error-codes.enum';
import { FundingService } from 'app/shared/funding/shared/services/funding.service';
import { combineLatest, of, Subscription } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';

@Component({
    selector: 'cdux-live-ticket',
    templateUrl: './live-ticket.component.html',
    styleUrls: ['./live-ticket.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LiveTicketComponent extends AbstractBetTotalComponent {
    public readonly INSUFFICIENT_FUNDS = BET_ERROR_CODES.INSUFFICIENT_FUNDS;

    @Input() public set raceStatus(raceStatus: enumRaceStatus) {
        this.wagerClosed = !TrackService.isWagerableRace(raceStatus);
        this.shareBetFeatureToggle = false;
        this.showShareBetButton = false;
        if (this.wagerClosed) {
            this.message = 'Wagering is Closed'
            if (this.isSubmittedState) {
                this.finalizeSubmittingBet();
            }
        } else {
            this.message = 'Select Runners'
        }
    }

    public wagerClosed: boolean;
    public message: string;
    public depositButtonText: string;
    public shareBetFeatureToggle: boolean;
    public showShareBetButton: boolean;
    public insufficientFundsError: boolean;

    private _featureToggleQuerySubscription: Subscription;

    constructor(
        _environment: ENVIRONMENT,
        _translateService: TranslateService,
        _wagerValidationService: WagerValidationService,
        localBetCalculatorService: BetCalculatorBusinessService,
        _betsService: BetsBusinessService,
        _stringSlugify: StringSlugifyPipe,
        _sessionService: JwtSessionService,
        _changeDetector: ChangeDetectorRef,
        _eventTrackingService: EventTrackingService,
        _betShareService: BetShareBusinessService,
        localConditionalWageringService: ConditionalWageringBusinessService,
        _betSlipErrorService: BetSlipErrorsService,
        _betSlipService: BetSlipBusinessService,
        _wagerCalculator: WagerCalculatorService,
        _sidebarService: SidebarService,
        _featureToggleService: FeatureToggleDataService,
        protected _toteDataService: ToteDataService,
        private _fundingService: FundingService,
        _betSlipBusinessService: BetSlipBusinessService,
        _router: Router,
        _tournamentSession: TournamentsSessionService
        
    ) {
        super(
            _environment,
            _wagerValidationService,
            localBetCalculatorService,
            _betsService,
            _betShareService,
            localConditionalWageringService,
            _sidebarService,
            _betSlipErrorService,
            _wagerCalculator,
            _eventTrackingService,
            _featureToggleService,
            _translateService,
            _sessionService,
            _changeDetector,
            _toteDataService,
            _betSlipBusinessService,
            _router,
            _tournamentSession,
        );
        this.depositButtonText = this._translateService.translate('deposit-text', 'wagers');
    }

    public resetTicket(): void {
        this.isSubmittedState = false;
        this.lastTransactionId = null;
        this.submittedWagerState = null;
        this.isAdvancedOptionsExpanded = false;
        this._changeDetector.markForCheck();
    }

    public prepareForSubmit(): void {
        this.submitting = true;
        this._clearError()
        this._changeDetector.markForCheck();
    }

    public handleSubmitSuccess(result: IBetResult): void {
        this.submitting = false;
        this.reset();
        this.lastTransactionId = !!result ? result.confirmationId : null;
        this.isSubmittedState = true;
        this._changeDetector.markForCheck();
    }

    public handleSubmitError(error?: string, betId?: string): void {
        this.submitting = false;
        this.insufficientFundsError = this._betSlipErrorService.getError(betId)?.errorCode === BET_ERROR_CODES.INSUFFICIENT_FUNDS;
        this._changeDetector.markForCheck();
    }

    public prepareForShare(): void {
        this.sharing = true;
        this._changeDetector.markForCheck();
    }

    public handleShareSuccess(): void {
        setTimeout(() => {
            this.sharing = false;
            this.reset();
            this._changeDetector.markForCheck();
        }, this.SHARE_BET_ANIMATION_TIME);
    }

    public handleShareError(): void {
        setTimeout(() => {
            this.sharing = false;
            this._changeDetector.markForCheck();
        }, this.SHARE_BET_ANIMATION_TIME);
    }

    public prepareForConditions() {
        this.conditioning = true;
        this._changeDetector.markForCheck();
    }

    public handleConditionsSuccess() {
        setTimeout(() => {
            this.conditioning = false;
            this.reset();
            this._changeDetector.markForCheck();
        }, this.CONDITIONAL_BET_ANIMATION_TIME);
    }

    public handleConditionsError() {
        setTimeout(() => {
            this.conditioning = false;
            this._changeDetector.markForCheck();
        }, this.CONDITIONAL_BET_ANIMATION_TIME);
    }

    public prepareForSave(): void {
        this.saving = true;
        this._clearError();
        this._changeDetector.markForCheck();
    }

    public handleSaveSuccess(): void {
        setTimeout(() => {
            this.saving = false;
            this.reset();
            this._changeDetector.markForCheck();
        }, this.SAVE_BET_ANIMATION_TIME);
    }

    public handleSaveError(): void {
        setTimeout(() => {
            this.saving = false;
            this._changeDetector.markForCheck();
        }, this.SAVE_BET_ANIMATION_TIME);
    }

    public handleKeepPicksToggle(): void {
        this.toggleKeepPicks();
    }

    public handleAdvOptsExpansion() {
        this.isAdvancedOptionsExpanded = !this.isAdvancedOptionsExpanded;
    }

    public set wagerState(v: WagerState) {
        super.wagerState = v;
        // handle event here
        this._shouldShowShareButton(v?.basicTrack);
    }

    public get wagerState(): WagerState {
        return super.wagerState;
    }

    public _shouldShowShareButton(track: ITrackBasic): void {
        const featureToggle = this._featureToggleService.watchFeatureToggle(enumFeatureToggle.BETSHARE);
        const buttonToggle = this._featureToggleService.watchFeatureToggle(enumFeatureToggle.BET_SHARE_TOTAL_BAR);
        const ghToggle = this._featureToggleService.watchFeatureToggle(enumFeatureToggle.BETSHARE_DISABLE_GH);
        const stateObs = this._betShareService.getBetShareConfig().pipe(map(() => {
            return this._betShareService.unrestrictedState();
        }));
        
        const obsArr = track.TrackType === enumTrackType.GREY ? [featureToggle, buttonToggle, ghToggle]
            : [featureToggle, buttonToggle , of(false)];

            if (this._featureToggleQuerySubscription) {
                this._featureToggleQuerySubscription.unsubscribe();
            }

            this._featureToggleQuerySubscription = combineLatest(obsArr)
            .pipe(flatMap(([betShareFT, shareBetTotalBarFT, disableGH]) => {
                const buttonToggledOn = betShareFT && shareBetTotalBarFT && !disableGH;
                if (buttonToggledOn && this._sessionService.isLoggedIn()) {
                    return stateObs;
                } else {
                    return of(buttonToggledOn);
                }
            })).subscribe(result => {
                this.shareBetFeatureToggle = result;
                this._changeDetector.detectChanges();
                this.showShareBetButton = this.shareBetFeatureToggle;
            });
    }

    public openDepositFlow() {
        if (!this.insufficientFundsError) {
            return;
        }

        //not adding action and returningBetId query params because we don't want bet slip to open on return
        this._fundingService.postDepositRedirectURL = this._router.url.replace(/\?.*/, '');
        this._router.navigate(['/', 'deposit']);
    }

    private _clearError() {
        this.errorText = null;
        this.insufficientFundsError = false;
    }
}
