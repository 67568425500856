export const LANG_COMMON_NAME = 'common';

export const LANG_COMMON = {
  'registration-gan-login-error-timeout': 'Sorry, we’re unable to fill your account info, but you can still register.',
  'registration-ssn-alert-header': 'Why do we need this?',
  'registration-error-personal-info': 'Please be sure all your information is correct and try again.',
  'registration-fail-soft-header': 'There was a problem creating your account',
  'registration-fail-soft-body': 'You can complete your online registration by providing additional documents.',
  'registration-fail-soft-body-call': 'Or call Player Services to verify your identity and complete registration at',
  'registration-required-docs': 'You will need the following:' +
    '<ol>' +
    '    <li>Driver&rsquo;s license OR Government ID</li>' +
    '    <li>Social Security Card OR Legal Tax' +
    '        Document</li>' +
    '    <li>Utility Bill, showing a valid address</li>' +
    '</ol>',
  'registration-ssn-tooltip': `
        <h4 class="header-sub">Why We Need Your SSN</h4>
        <p>We collect your Social Security Number (SSN) for two main reasons:</p>
        <ol>
            <li>
                <span>Identity Verification</span>
                We use your SSN to verify your age and state of residence.
            </li>
            <li>
                <span>IRS Requirements</span>
                The IRS requires all online wagering sites collect your SSN in order to report your taxable winnings.
            </li>
        </ol>
        <p>We protect your personal information with the latest website security technologies. We safeguard your information with SSL (Secure Sockets Layer). SSL encrypts your personal information so that it cannot be read in transit by a third party. Your information is safe with us.</p>`,
  'registration-fail-soft-fax-copy': 'You may also fax these documents to',
  'registration-fail-hard-header': 'We were unable to create your account',
  'registration-fail-hard-body': 'Please call Player Services at ',
  'registration-fail-technical-header': 'We were unable to create your account',
  'registration-fail-technical-body': 'Sorry, we are unable to process registrations at this time.',
  'registration-fail-verify-path': '/verify/upload',
  'registration-account-exists-header': 'Already have an account?',
  'forgot-password-path': '/password/forgot',
  'registration-success-header': 'Almost there...',
  'registration-success-message': 'Now fund your account and start betting on today\'s top races!',
  'registration-success-message-woffer': `<p>You have successfully claimed the %s.</p>
    <p>Now fund your account and start betting on today\'s top races!</p>`,
  'bet-share-registration-success-header': 'Almost there...',
  'bet-share-registration-success-message': 'Now fund your account and start betting on today\'s top races!',
  'bet-share-registration-success-message-woffer': `<p>You have successfully claimed the %s.</p>
    <p>Now fund your account and start betting on today\'s top races!</p>`,
  'bet-share-registration-success-message-woffer-funded': `<p>You have successfully claimed the %s.</p>`,
  'availability-hours': '9 AM to 12 AM / ET',
  'mail-locale': 'PO Box 8510',
  'mail-locale-2': '',
  'mail-city': 'Lexington',
  'mail-state': 'Kentucky',
  'mail-zip': '40533',
  'mail-overnight-locale': '801 Corporate Drive',
  'mail-overnight-locale-2': '2nd Floor',
  'mail-overnight-city': 'Lexington',
  'mail-overnight-state': 'Kentucky',
  'mail-overnight-zip': '40503',
  'technical-difficulties': 'We are experiencing technical difficulties. If you have any questions ',
  'tote-down-message': 'While we perform regular site maintenance between approximately 3-6am ET daily, track menus and wagering at %s will be unavailable. For questions regarding this message or other inquiries, ',
  'balance-unavailable-during-tote-down': 'Balance requests are temporarily unavailable until our nightly maintenance is complete. We apologize for the inconvenience.'
};
