import {
    AfterViewChecked,
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewEncapsulation,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, UrlTree } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil, distinctUntilChanged, tap } from 'rxjs/operators';

import { ENVIRONMENT } from '@cdux/ng-core';
import { CduxMediaToggleService } from '@cdux/ng-platform/web';
import { enumFeatureToggle, EventClickType, FeatureToggleDataService, JwtSessionService } from '@cdux/ng-common';

import { SidebarService } from '../../sidebar/sidebar.service';
import { FullPageFundingConstants } from 'app/shared/funding/full-page-funding/full-page-funding.constants';
import { FundingService } from 'app/shared/funding/shared/services/funding.service';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';
import { LOGIN_REDIRECT_PATH, SSO_LOGIN_PATH } from 'app/app.routing.constants';

export enum ActionsEnum {
    'REGISTRATION' = 'registration'
}

const SPLIT_BETS_BUTTON_FT = 'SPLIT_BET_BUTTON';
const RUNNER_SEARCH_FT = 'RUNNER_SEARCH';

@Component({
    selector: 'cdux-menu-items',
    templateUrl: './menu-items.component.html',
    styleUrls: ['./menu-items.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MenuItemsComponent implements OnInit, OnDestroy, AfterViewChecked {
    @Output()
    public depositClick = new EventEmitter<void>();

    /**
     * Setter for if the nav panel is open.
     *
     * @param {boolean} val
     */
    @Input() public set navPanelOpen(val: boolean) {
        this.isActive = val;
    }

    public isActive: boolean = false;

    // Expose EventClickType so we can use it in the template for click events
    public eventClickType = EventClickType;

    public isLoggedIn: boolean;

    public affId = this._environment.affiliateId;
    public apiUsername = this._environment.appConfig.username;

    public ssoLoginFT: boolean = false;
    public fullPageDepositFT: boolean = false;
    public splitButtonFT: boolean = false;
    public searchEnabled: boolean = false;
    public savedBetsEnabled: boolean = false;
    public isSmallGlass: boolean = false;
    public tournamentSelected: boolean = false;

    private _onDestroy: Subject<any> = new Subject();
  

    constructor(
        
        private _environment: ENVIRONMENT,
        private _featureToggleService: FeatureToggleDataService,
        private _fundingService: FundingService,
        private _mediaQuery: CduxMediaToggleService,
        private _renderer: Renderer2,
        private _sessionService: JwtSessionService,
        private _router: Router,
        private _sidebarService: SidebarService,
        private _tournamentSelection: TournamentsSessionService,
        @Inject(DOCUMENT) private _document: Document
        ) {
            // Empty
        }

    ngOnInit() {
        this.splitButtonFT = this._featureToggleService.isFeatureToggleOn(SPLIT_BETS_BUTTON_FT);
        this.searchEnabled = this._featureToggleService.isFeatureToggleOn(RUNNER_SEARCH_FT);
        this.savedBetsEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.SAVED_BETS);
        this.fullPageDepositFT = this._featureToggleService.isFeatureToggleOn(FullPageFundingConstants.FULL_PAGE_DEPOSIT_FT);
        this.ssoLoginFT = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.ENABLE_SSO_LOGIN);

        if (this.fullPageDepositFT) {
            this.addTswcUserBalanceScript();
        }
        // this.isSmallGlass = this._mediaQuery.query('phone');
        this._mediaQuery.registerQuery('phone').pipe(
            distinctUntilChanged(),
            tap(isSmallGlass => this.isSmallGlass = isSmallGlass),
            takeUntil(this._onDestroy),
        ).subscribe();
        // Set and monitor authentication state.
        this.isLoggedIn = this._sessionService.isLoggedIn();
        this._sessionService.onAuthenticationChange
            .pipe(takeUntil(this._onDestroy))
            .subscribe(isLoggedIn => this.isLoggedIn = isLoggedIn);

        this._tournamentSelection.onTournamentSelection.pipe(takeUntil(this._onDestroy)).subscribe((data) => {
            this.tournamentSelected = data ? true : false;
        });
    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
        if (this.fullPageDepositFT) {
            this._unlistener();
        }
    }

    ngAfterViewChecked(): void {
        if (this.fullPageDepositFT) {
            this.addUserBalanceComponent();
        }
    }

    public addTswcUserBalanceScript(): void {
        if (!customElements.get('tswc-user-balance')) {
            const script = this._renderer.createElement('script');
            // webComponentPath is added to the index.html file as part of the post build and is defined
            // for each affiliate in their build/environments/[affid]/environment.[env].js file. We default
            // to ITE when the variable is not set because the post build process does not run when we
            // run TUX locally.
            script.src = [
                typeof webComponentPath !== 'undefined' ? webComponentPath : 'https://ite.twinspires.com/web-components/',
                'solo/tswc-user-balance/v1/main.js'
            ].join('');
            this._renderer.appendChild(this._document.body, script);
        }
    }

    public addUserBalanceComponent(): void {
        const webCompDiv = document.getElementById('deposit-web-component');
        if (webCompDiv && webCompDiv.innerHTML === '') {
            const balanceComp = this._renderer.createElement('tswc-user-balance');
            this._renderer.setAttribute(balanceComp, 'affiliate-id', '' + this._environment.appConfig.affiliateId);
            this._renderer.setAttribute(balanceComp, 'api-username', this._environment.appConfig.username);
            this._renderer.setAttribute(balanceComp, 'size-mode', this.isSmallGlass ? 'small' : 'large');
            this._renderer.appendChild(webCompDiv, balanceComp);
            this._unlistener = this._renderer.listen(balanceComp, 'depositNavigation', (e) => {
                this.depositClick.emit();
                this._fundingService.postDepositRedirectURL = this._router.url.split('?')[0];
                this._router.navigate(['/deposit']);
            });
        }
    }

    public login(): void {
        const route = this.ssoLoginFT ? SSO_LOGIN_PATH : LOGIN_REDIRECT_PATH;
        // remove "registration" action from route parameters if present
        const routeUrl: UrlTree = this._router.parseUrl(this._router.url);
        let regPanelActive = false;
        if (!!routeUrl.queryParams.action && routeUrl.queryParams.action === ActionsEnum.REGISTRATION) {
            delete routeUrl.queryParams.action;
            regPanelActive = true;
        }
        this._sessionService.redirectLoggedInUserUrl = this._router.serializeUrl(routeUrl);
        this._router.navigate([route])
            .then(() => {
                // close any open navPanels
                if (this._sidebarService.isOpen && regPanelActive) {
                    this._sidebarService.close(true);
                }
            });
    }

    private _unlistener(): void {}
}
