import { Component } from '@angular/core';
import { AbstractMenuItemComponent } from 'app/shared/menu-items/components/abstract-menu-item.component';
import { MenuItemsEnum } from 'app/shared/menu-items/enums/menu-items.enum';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { TodaysBetsContainerComponent } from 'app/shared/bets/components/todays-bets-container/todays-bets-container.component';
import { MyBetsBusinessService } from 'app/shared/bets/services/my-bets.business.service';

@Component({
    selector: 'cdux-todays-bets-menu-item',
    templateUrl: './todays-bets-menu-item.component.html'
})
export class TodaysBetsMenuItemComponent extends AbstractMenuItemComponent {
    /**
     * TODO: Provide a click event.
     *
     * @type [EventClickType}
     */
    protected clickEvent;

    /**
     * Used for determining when an external call is made to open the bets in a nav panel.
     * @type {MenuItemsEnum}
     */
    protected navTarget: MenuItemsEnum = MenuItemsEnum.TODAYS_BETS;

    public betCount: number;

    constructor(
        protected _eventTrackingService: EventTrackingService,
        protected _sidebarService: SidebarService,
        private _myBetsBusinessService: MyBetsBusinessService
    ) {
        super(_sidebarService, _eventTrackingService);
        this._subscriptions.push(
            this._myBetsBusinessService.getMyBetsCache()
                .subscribe(active => {
                    this.betCount = active && active.length || 0;
                }
            )
        )
    }

    protected activate(): void {
        this._sidebarService.loadComponent(TodaysBetsContainerComponent.getSidebarComponent(), null, {
            clearHistory: true,
        });
    }

    protected deactivate(): void {
        this._sidebarService.close(true);
    }

    public formatBetCount() {
        return this.betCount > 999 ? '999+' : this.betCount;
    }
}
