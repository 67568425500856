import { Injectable } from '@angular/core';
import {
    ConfigurationDataService,
    enumConfigurationStacks,
    IPlaidConfig,
    PlaidDataService,
    ThirdPartyInjectorUtil,
    WindowRefService,
    ILinkTokenResponse,
    IPlaidHandler,
    ILogPlaidErrorRequest,
    ILogPlaidErrorResponse,
    IPlaidErrorAndMetadata
} from '@cdux/ng-common';
import { map, switchMap } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';

@Injectable()
export class PlaidBusinessService {
    private _plaidHandler: IPlaidHandler;

    constructor(
        private _configurationService: ConfigurationDataService,
        private _windowRef: WindowRefService,
        private _plaidDataService: PlaidDataService
    ) {}

    public initializePlaid(): Observable<boolean> {
        if (!this.isInitialized()) {
            return this._configurationService.getConfiguration(enumConfigurationStacks.TUX, ['plaid_widget_src', 'plaid_connect_src'])
                .pipe(
                    switchMap((config) => {
                        if(!!config && config['plaid_widget_src']) {
                            const attribs = !!config['plaid_connect_src'] ?   {'connect-src': config['plaid_connect_src']} : null;
                            return from(ThirdPartyInjectorUtil.loadScript(config['plaid_widget_src'], attribs))
                                .pipe(map(el =>  !!el));
                        } else {
                            return of(false);
                        }
                    }));
        } else {
            return of(true);
        }
    }

    public isInitialized(): boolean {
        return !!this._windowRef.nativeWindow['Plaid'];
    }

    public openPlaidWidget(config: IPlaidConfig): void {
        if (this.isInitialized()) {
            if(!this._plaidHandler) {
                this._plaidHandler = this._windowRef.nativeWindow['Plaid'].create(config);
            }
            this._plaidHandler.open();
        }
    }

    public closePlaidWidget(){
        if(!!this._plaidHandler) {
            this._plaidHandler.destroy();
            this._plaidHandler = null;
        }
    }

    public getLinkToken(): Observable<ILinkTokenResponse> {
        return this._plaidDataService.getLinkToken();
    }

    public getUpdateModeToken(): Observable<ILinkTokenResponse> {
        return this._plaidDataService.getUpdateModeLinkToken();
    }

    public logPlaidError(error: IPlaidErrorAndMetadata): Observable<ILogPlaidErrorResponse> {
        const errorReq: ILogPlaidErrorRequest = {
            errorMetadata: error
        }
        return this._plaidDataService.logPlaidError(errorReq);
    }

}
