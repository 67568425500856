<div class="menu-items" [class.is-active]="isActive">
    <ng-container *ngIf="searchEnabled && !tournamentSelected">
        <cdux-search-menu-item *ngIf="!isSmallGlass"></cdux-search-menu-item>
        <cdux-small-glass-search-menu-item *ngIf="isSmallGlass"></cdux-small-glass-search-menu-item>
    </ng-container>

    <ng-container *ngIf="ssoLoginFT && !isLoggedIn; else regularLogin">
        <a class="link join-menu-item sso-item" [class.is-active]="isActive" [class.success]="isSuccess" (click)="login()">Log In<i class="menu-item-icon join"></i></a>
    </ng-container>
</div>

<ng-template #regularLogin>
    <ng-container *ngIf="!isLoggedIn; else loggedInTemplate">
        <cdux-registration-menu-item id="registrationHeader" title="Register"></cdux-registration-menu-item>
        <a class="link signin-menu-item" (click)="login()" routerLinkActive="active" [cduxLogClick]="eventClickType.MAIN_NAV_MENU_SIGN_IN">Sign In</a>
    </ng-container>
</ng-template>

<ng-template #loggedInTemplate>
    <ng-container *ngIf="splitButtonFT">
        <cdux-todays-bets-menu-item id="todaysBetsHeader" title="Bets" [cduxLogClick]="eventClickType.MAIN_NAV_MENU_BETS_BUTTON"></cdux-todays-bets-menu-item>
        <cdux-bet-slip-menu-item *ngIf="savedBetsEnabled && !tournamentSelected" id="betSlipHeader" title="Saved" [cduxLogClick]="eventClickType.MAIN_NAV_MENU_SAVED_BUTTON"></cdux-bet-slip-menu-item>
    </ng-container>
    <ng-container *ngIf="!splitButtonFT">
        <cdux-bets-menu-item id="betsHeader" title="Bets" [cduxLogClick]="eventClickType.MAIN_NAV_MENU_BETS"></cdux-bets-menu-item>
    </ng-container>
    <cdux-deposit-menu-item id="accountBalanceHeader" title="Deposit" *ngIf="!fullPageDepositFT"></cdux-deposit-menu-item>
    <cdux-profile-menu-item id="toggleAccount" title="welcome"></cdux-profile-menu-item>
    <div *ngIf="fullPageDepositFT" id="deposit-web-component" class="link"></div>
</ng-template>
