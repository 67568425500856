import { BET_ERROR_CODES } from 'app/shared/bet-slip/enums/bet-error-codes.enum';
import { ChangeDetectorRef, Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { delay, map, take, takeUntil, tap } from 'rxjs/operators';

import { ENVIRONMENT } from '@cdux/ng-core';

import {
    Bet,
    BetCalculatorBusinessService,
    Entry,
    enumBetModifier,
    enumFeatureToggle,
    EventClickAttributeType,
    EventClickType,
    FeatureToggleDataService,
    IBetResult,
    IPoolType,
    JwtSessionService,
    RunnerListConfig,
    ToteDataService,
    TranslateService,
    UserEventEnum,
    WagerCalculatorService,
    WagerState,
    WagerStateUtil,
    WagerValidationCodes,
    WagerValidationService,
} from '@cdux/ng-common';

import { BetsModifierDisplayValue } from '@cdux/ng-fragments';

import { BetsBusinessService } from 'app/shared/bet-slip/services/bets.business.service';
import { EventTrackingService } from 'app/shared/event-tracking/services/event-tracking.service';
import { AbstractWageringViewEventComponent } from 'app/shared/wager-views/components';
import { WagerEventTypeEnum } from 'app/shared/wager-views/enums';
import { BetActionsEnum } from 'app/shared/bet-slip/enums/bet-actions.enum';
import { BetShareBusinessService } from '../../services/betshare.business.service';
import {
    BetsContainerComponent,
    IBetContainerNavPanelProps
} from 'app/shared/bets/components/bets-container/bets-container.component';
import { SidebarService } from 'app/shared/sidebar/sidebar.service';
import { BetSlipErrorsService } from '../../services/bet-slip-errors.service';
import { IBetError } from '../../interfaces/bet-error.interface';
import { BetSlipBusinessService } from '../../services/bet-slip.business.service';
import { Router } from '@angular/router';
import { BetSlipContainerComponent } from 'app/shared/bets/components/bet-slip-container/bet-slip-container.component';
import { ConditionalWageringBusinessService } from '../../services/conditional-wagering.business.service';
import { IConditionalWagerConfig } from '../../interfaces/conditional-wager-config.interface';
import { WAGER_CONDITIONS } from '../../enums/conditional-wagering.enum';
import { TournamentsSessionService } from 'app/shared/tournaments-session/services/touranments-session.service';

@Directive()
export abstract class AbstractBetTotalComponent extends AbstractWageringViewEventComponent implements OnInit, OnDestroy {
    private static KEEP_PICKS_FLAG_STORAGE_KEY = 'cdux-keep-picks';
    private static REPEAT_BET_DELAY_MS = 800;
    @Output() resetEntrySelections: EventEmitter<void> = new EventEmitter<void>();

    private _submittedWagerState: WagerState;
    public get submittedWagerState(): WagerState {
        return this._submittedWagerState;
    }
    public set submittedWagerState(value: WagerState) {
        this._submittedWagerState = value;
    }
    // TODO: Add input for wagering view
    private _wagerState: WagerState;
    @Input()
    public set wagerState(v: WagerState) {
        const oldWager = this._wagerState;
        this._wagerState = v;
        if (v !== oldWager && (!this.submittedWagerState || this.shouldResetSubmittedWager(oldWager, v))) {
            // reset the submittedWagerState as entries are changed
            if (this.submittedWagerState) {
                this.resetTicket();
            }
            // When switching between Classic and TV, we need to make sure that the first time the wagerState input
            // is called it doesn't attempt to fire the user event in the event we have already constructed a wager.
            this.handleValidation(v, typeof oldWager === 'undefined');
        }
        // update allowsBetShare:
        this.allowsBetShare = this._wagerState.basicTrack.hasOwnProperty('AllowsBetShareWagering') && this._wagerState.basicTrack['AllowsBetShareWagering'] === true;
        // update allowsConditional wager:
        this.allowsConditional = this._wagerState.basicTrack.hasOwnProperty('AllowsConditionalWagering') && this._wagerState.basicTrack['AllowsConditionalWagering'] === true;
        // update canShowAdvancedOptions flag:
        const bet: Bet = Bet.fromWagerState(this._wagerState);
        this._conditionalWageringService.getCondWagerConfig().pipe(
            take(1)
        ).subscribe((condConfig) => {
            this.condWagerConfig = condConfig;
            this.isValidCondWagerType = this.checkValidCondWagerType(bet.poolType, bet.runners);
            this.canShowAdvancedOptions = (this.isBetShareEnabled && this.allowsBetShare) || (this.isConditionalWagerToggleOn && this.allowsConditional && this.isValidCondWagerType);
        });
        this.isAdvancedOptionsExpanded = false; // reset Advanced Options expanding state to closed.
    }

    public get wagerState(): WagerState {
        // TODO: refactor the ticket component to use current Wager state or submitted wager state --US31708(https://rally1.rallydev.com/#/114418312492d/detail/userstory/353639160520)
        return this.submittedWagerState ? this._submittedWagerState : this._wagerState;
    }

    public runnerListConfig: RunnerListConfig;
    public raceNumber: number = 1;
    public raceLegs: number = 1;

    public displayModifier: BetsModifierDisplayValue;
    public total: string;
    public entriesAreSelected: boolean = false;
    public sharing: boolean = false;
    public conditioning: boolean = false; // US36510: an indicator that the CONDITIONS button clicked and saving the record to Saved wagers
    public saving: boolean = false;
    public submitting: boolean = false;
    public isValid: boolean = false;
    public errorText: string;
    public lastTransactionId: string;
    public isSubmittedState: boolean = false;
    public isBetShareEnabled: boolean = false;
    public isSavedBetsEnabled: boolean = false;
    public allowsBetShare: boolean = true;
    public isConditionalWagerToggleOn: boolean = false;
    public allowsConditional: boolean = true;
    public splitBetButtonFT: boolean = false;
    public keepPicks: boolean = false;
    public tournamentSelected: boolean = false;
    public readonly SAVE_BET_ANIMATION_TIME = 500;
    public readonly SHARE_BET_ANIMATION_TIME = 500;
    public readonly CONDITIONAL_BET_ANIMATION_TIME = 500; // US36510: saving conditional wager animation timer
    public readonly WAGER_ERRORS_LANG = 'wager-errors';

    public betActionTypes = BetActionsEnum;

    public destroy = new Subject();

    public toteDate: string;
    public advancedOptionsToggle: boolean = false; // This is a feature toggle flag set in BOSS with key "ADVANCED_OPTIONS",
                                                   // TODO: since this toggle is temporary, we will remove after the feature F3281 fully rollout
                                                   // Cleanup story: US37082: https://rally1.rallydev.com/#/57152849832d/iterationstatus?detail=%2Fuserstory%2F452578131420&fdp=true?fdp=true
    public canShowAdvancedOptions: boolean; // true if a track allows either betShare wager or/and conditional wager, otherwise false;
    public isAdvancedOptionsExpanded: boolean = false;
    public isValidCondWagerType: boolean = false;
    public condWagerConfig: IConditionalWagerConfig;

    constructor(
        private _environment: ENVIRONMENT,
        private _wagerValidationService: WagerValidationService,
        private _betCalculatorService: BetCalculatorBusinessService,
        private _betsService: BetsBusinessService,
        protected _betShareService: BetShareBusinessService,
        private _conditionalWageringService: ConditionalWageringBusinessService,
        private _sidebarService: SidebarService,
        protected _betSlipErrorService: BetSlipErrorsService,
        private _wagerCalculator: WagerCalculatorService,
        private _eventTrackingService: EventTrackingService,
        protected _featureToggleService: FeatureToggleDataService,
        protected _translateService: TranslateService,
        protected _sessionService: JwtSessionService,
        protected _changeDetector: ChangeDetectorRef,
        protected _toteDataService: ToteDataService,
        private _betSlipBusinessService: BetSlipBusinessService,
        protected _router: Router,
        private _tournamentSession: TournamentsSessionService
    ) {
        super();
    }

    ngOnInit(): void {
        this.isBetShareEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.BETSHARE);
        this.isSavedBetsEnabled = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.SAVED_BETS)
        this.splitBetButtonFT = this._featureToggleService.isFeatureToggleOn('SPLIT_BET_BUTTON');
        this.isConditionalWagerToggleOn = this._featureToggleService.isFeatureToggleOn(enumFeatureToggle.CONDITIONAL_WAGERING);

        // US36538: check ADVANCED_OPTIONS toggle for now, until F3281 fully rollout, then we clean up this toggle
        this.advancedOptionsToggle = this._featureToggleService.isFeatureToggleOn('ADVANCED_OPTIONS');

        this._loadKeepPicks();
        if (this.wagerState) {
            this.handleValidation(this.wagerState, true);
            this._conditionalWageringService.getCondWagerConfig().pipe(take(1)).subscribe((condConfig) => {
                this.condWagerConfig = condConfig;
            })
        }

        this._toteDataService.currentRaceDate().pipe(take(1)).subscribe((date) => {
            this.toteDate = date;
        });

        this._tournamentSession.onTournamentSelection.pipe(takeUntil(this.destroy)).subscribe((data) => {
            this.tournamentSelected = data ? true : false;
        });
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.destroy.next();
        this.destroy.complete();
    }

    public abstract prepareForSubmit(): void;
    public abstract handleSubmitSuccess(result: IBetResult): void;
    public abstract handleSubmitError(error?: string, betId?: string): void;

    public abstract prepareForShare(): void;
    public abstract handleShareSuccess(): void;
    public abstract handleShareError(): void;

    public abstract prepareForConditions(): void;
    public abstract handleConditionsSuccess(): void;
    public abstract handleConditionsError(): void;

    public abstract prepareForSave(): void;
    public abstract handleSaveSuccess(): void;
    public abstract handleSaveError(): void;

    public abstract resetTicket(): void;

    public logClickEvent(betActionType: BetActionsEnum) {
        const ts = Date.now();

        const attributes = [
            { attrId: EventClickAttributeType.QUICK_BET_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
            { attrId: EventClickAttributeType.QUICK_BET_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
            { attrId: EventClickAttributeType.QUICK_BET_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
            { attrId: EventClickAttributeType.QUICK_BET_RACE_DATE, data: this.toteDate, timestamp: ts }
        ];

        switch (betActionType) {
            case BetActionsEnum.SAVE_BET:
                this._eventTrackingService.logClickEvent(EventClickType.ADD_TO_BETSLIP, attributes);
                break;
            case BetActionsEnum.SUBMIT_BET:
                this._eventTrackingService.logClickEvent(EventClickType.QUICK_BET, attributes);
                break;
            case BetActionsEnum.REPEAT_BET:
                this._eventTrackingService.logClickEvent(EventClickType.REPEAT_BET, attributes);
                break;
            case BetActionsEnum.SHARE_BET:
                this._eventTrackingService.logClickEvent(EventClickType.SHARE_BET_TOTAL_BAR, attributes);
                break;
            default:
            // We shouldn't get in here, but if we do, that's OK, we won't log any click event
        }
    }

    public initiateBetShare() {
        if (this.sharing) {
            return;
        } else {

            const bet = Bet.fromWagerState(this._wagerState);

            this._betShareService.getBetShareConfig().pipe(
                take(1)
            ).subscribe((betShareConf) => {
                if (betShareConf) {
                    this._betShareService.initializeBetShare(bet);
                }
                this.saveBet(BetActionsEnum.SHARE_BET, bet);
                if (this._sessionService.isLoggedIn()) {
                    this.sharing = false;
                    this._openBetSlip({ betShareId: bet.betShareId });
                    this._changeDetector.markForCheck();
                }
            });
        }
    }

    public initiateConditions() {
        if (this.conditioning) {
            return;
        } else {
            const conditionalBet: Bet = Bet.fromWagerState(this._wagerState);
            if (this.checkValidCondWagerType(conditionalBet.poolType, conditionalBet.runners)) {
                conditionalBet.cost = this._betCalculatorService.calculate(conditionalBet).toString();
                conditionalBet.conditional = true;
                conditionalBet.conditionalMtp = 0;
                if (this.condWagerConfig.showOdds.includes(conditionalBet.poolType.Code.toUpperCase())) {
                    conditionalBet.conditionalOdds = 10;
                    conditionalBet.conditionalProbablePayout = null;
                } else {
                    conditionalBet.conditionalOdds = null;
                    conditionalBet.conditionalProbablePayout = 50;
                }

                this.saveBet(BetActionsEnum.SAVE_CONDITIONAL_BET, conditionalBet);
                if (this._sessionService.isLoggedIn()) {
                    this.conditioning = false;
                    this._openBetSlip({});
                    this._changeDetector.markForCheck();
                }
            }
        }
    }

    public saveBet(betActionType?: BetActionsEnum, bet?: Bet): void {
        if (!betActionType) {
            betActionType = BetActionsEnum.SAVE_BET;
        }
        this.logClickEvent(betActionType);
        this._preprocessBetAndCall(false, (processedBet) => {
            this._betSlipBusinessService.currentBet = Bet.fromBetlike(processedBet);
            this._betSlipBusinessService.resetBet();
            switch (betActionType) {
                case BetActionsEnum.SAVE_BET:
                    this.prepareForSave();
                    this._addToBetSlip(processedBet).then(() => {
                        this.handleSaveSuccess();
                    });
                    break;
                case BetActionsEnum.SHARE_BET:
                    this.prepareForShare();
                    this._addToBetSlip(processedBet).then(() => {
                        this.handleShareSuccess();
                    });
                    break;
                case BetActionsEnum.SAVE_CONDITIONAL_BET:
                    this.prepareForConditions();
                    this._addToBetSlip(processedBet).then(() => {
                        this.handleConditionsSuccess();
                    });
                    break;
                default:
                    // do nothing
                    break;
            }
        }, bet);
    }

    public submitQuickBet(betAction?: BetActionsEnum): void {
        this.logClickEvent(BetActionsEnum.SUBMIT_BET);
        this._preprocessBetAndCall(true, (bet) => {
            this.prepareForSubmit();
            this._submitBet(bet, betAction);
        });
    }

    private _preprocessBetAndCall(isQuickBet: boolean, callback: (bet: Bet) => void, betToProcess?: Bet): void {

        let bet: Bet;
        betToProcess ? bet = betToProcess : bet = Bet.fromWagerState(this.wagerState);

        const isLoggedIn: boolean = this._sessionService.isLoggedIn();
        // If there's a current bet, then I know that I want to save the current wager as that bet.
        // This avoids duplicating an edited bet.
        if (this._betSlipBusinessService.currentBet) {
            bet.id = this._betSlipBusinessService.currentBet.id;
        }
        bet.userName = isLoggedIn ? this._sessionService.getUserInfo().username : '';
        bet.betCreatedTimestamp = new Date().getTime();
        bet.showInBetSlip = !isQuickBet;
        bet.isQuickBet = isQuickBet;
        if (!isLoggedIn) {
            this._addToBetSlip(bet).then(
                () => {
                    this._sessionService.redirectLoggedInUserUrl = this._router.url;
                    this._router.navigate(['/login']);
                },
                // TODO - what should happen if the bet fails to save?
                () => {}
            );
        } else {
            callback(bet);
        }
    }

    private _submitBet(bet: Bet, action?: BetActionsEnum): void {
        this._betsService.submitWager(bet, this.total).pipe(
            takeUntil(this.destroy),
            delay(AbstractBetTotalComponent.REPEAT_BET_DELAY_MS)
        ).subscribe(
            (result: IBetResult) => {
                if (result.success) {
                    if (!this.submittedWagerState) {
                        this.submittedWagerState = this.wagerState;
                    }
                    this.reset();
                    if (action) {
                        this._sendWagerSuccessEvent(action);
                    } else {
                        this._sendWagerSuccessEvent(BetActionsEnum.SUBMIT_BET);
                    }
                    this._removeFromBetSlip(bet);
                    this.handleSubmitSuccess(result);
                } else {
                    switch (result.message) {
                        case BET_ERROR_CODES.INSUFFICIENT_FUNDS:
                            this.errorText = this._translateService.translate(BET_ERROR_CODES.INSUFFICIENT_FUNDS_NODEPOSIT, this.WAGER_ERRORS_LANG);
                            break;
                        case BET_ERROR_CODES.RACE_CLOSED:
                            this.errorText = this._translateService.translate(BET_ERROR_CODES.RACE_CLOSED, this.WAGER_ERRORS_LANG);
                            break;
                        case BET_ERROR_CODES.RESTRICT_PHONE_ONLY:
                            this.errorText = this._translateService.translate(
                                BET_ERROR_CODES.RESTRICT_PHONE_ONLY,
                                this.WAGER_ERRORS_LANG,
                                TranslateService.OPT_FALLBACK_DISABLE,
                                BetSlipErrorsService.stateNamesMap[this._sessionService.getUserInfo().state],
                                this._translateService.translate('primary-phone-number-vanity', this._environment.affiliateId.toString())
                            );
                            break;
                        case BET_ERROR_CODES.SCRATCH:
                            this.errorText = this._translateService.translate(BET_ERROR_CODES.SCRATCH, this.WAGER_ERRORS_LANG);
                            break;
                        default:
                            this.errorText = this._translateService.translate(BET_ERROR_CODES.UNKNOWN_ERROR, this.WAGER_ERRORS_LANG);
                            break;
                    }
                    this._sendWagerFailureEvent(result.wagerId, action);
                    this.handleSubmitError(result.message, bet.id);
                }
            },
            (error) => {
                this.handleSubmitError(this._translateService.translate(BET_ERROR_CODES.UNKNOWN_ERROR, this.WAGER_ERRORS_LANG), bet.id);
                this._sendWagerFailureEvent(bet.id);
            }
        );
    }

    /**
     * loads Bets nav panel.
     */
    private _openBetSlip(options: IBetContainerNavPanelProps) {
        if (this.splitBetButtonFT) {
            this._sidebarService.loadComponent(BetSlipContainerComponent.getSidebarComponent(options), null, {
                clearHistory: true
            });
        } else {
            this._sidebarService.loadComponent(BetsContainerComponent.getSidebarComponent(options), null, {
                clearHistory: true
            });
        }
    }

    private _addToBetSlip(bet: Bet): Promise<Bet> {
        return this._betsService.saveWager(bet).pipe(
            takeUntil(this.destroy),
            map(savedWager => savedWager.data),
            tap((savedWager) => {
                savedWager.cost = this._betCalculatorService.calculate(savedWager)?.toString();
                this._betsService.logWagerEvent(UserEventEnum.BET_SAVED, [savedWager.getSubmittableWager()])
            }),
        ).toPromise().catch((error) => {
            this._sendSaveFailureEvent(error);
            this.handleSaveError();
        });
    }

    private _removeFromBetSlip(bet: Bet): Promise<void> {
        return this._betsService.removeWager(bet)
            .toPromise().catch(() => {});
    }

    private _logKeepPicksClickEvent() {
        const ts = Date.now();
        const attributes = [
            { attrId: EventClickAttributeType.KEEP_PICKS_STATE, data: this.keepPicks, timestamp: ts },
            { attrId: EventClickAttributeType.KEEP_PICKS_BRIS_CODE, data: this.wagerState.basicTrack.BrisCode, timestamp: ts },
            { attrId: EventClickAttributeType.KEEP_PICKS_TRACK_TYPE, data: this.wagerState.basicTrack.TrackType, timestamp: ts },
            { attrId: EventClickAttributeType.KEEP_PICKS_RACE_NUMBER, data: this.wagerState.basicTrack.RaceNum, timestamp: ts },
            { attrId: EventClickAttributeType.KEEP_PICKS_RACE_DATE, data: this.toteDate, timestamp: ts }
        ];
        this._eventTrackingService.logClickEvent(EventClickType.KEEP_PICKS, attributes);
    }

    protected reset(): void {
        if (!this.keepPicks) {
            this.resetEntrySelections.emit();
        }
    }

    protected handleValidation(wagerState: WagerState, ignoreUserEvent: boolean = false) {
        const hasPreviousSelectedEntries = this.entriesAreSelected;
        this.entriesAreSelected = this.areEntriesSelected(wagerState);

        if (this.entriesAreSelected && !!wagerState && !!wagerState.betNav) {
            if (!hasPreviousSelectedEntries && !ignoreUserEvent) {
                const bet = Bet.fromWagerState(this._wagerState);
                bet.cost = this._wagerCalculator.calculate(bet.getCalculableWager())?.toString();
                this._betsService.logWagerEvent(UserEventEnum.BET_ADDED, [bet.getSubmittableWager()]);
            }

            this.total = this._wagerCalculator.calculate(WagerStateUtil.calculableFromWagerState(wagerState)) + '';

            const poolType = wagerState.betNav && wagerState.betNav.type && wagerState.betNav.type.poolType || null;
            this.raceLegs = poolType && poolType.raceLegs || 1;
            this.raceNumber = wagerState.basicTrack && wagerState.basicTrack.RaceNum || 1;
            this.runnerListConfig =  {
                bettingInterests: wagerState.bettingInterests || [],
                subtype: wagerState.betNav && wagerState.betNav.modifier || null,
                selectionCount: poolType && poolType.selectionCount || 1
            };

            const validation = this._wagerValidationService.validate(WagerStateUtil.validatableWagerFromWagerState(wagerState));

            switch (validation) {
                case WagerValidationCodes.VALID:
                    this.isValid = true;
                    this.errorText = '';
                    this._sendWagerValidEvent();
                    break;
                case WagerValidationCodes.MINIMUM_VALUE_NOT_MET:
                    this.isValid = false;
                    this.errorText = this._translateService.translate(BET_ERROR_CODES.MIN_NOT_MET, 'wager-errors');
                    this._sendWagerInvalidEvent(this._betSlipErrorService.buildError(BET_ERROR_CODES.MIN_NOT_MET));
                    break;
                case WagerValidationCodes.MAXIMUM_VALUE_EXCEEDED:
                    this.isValid = false;
                    this.errorText = this._translateService.translate(BET_ERROR_CODES.MAX_EXCEEDED, 'wager-errors');
                    this._sendWagerInvalidEvent(this._betSlipErrorService.buildError(BET_ERROR_CODES.MAX_EXCEEDED));
                    break;
                case WagerValidationCodes.INFORMATION_INCOMPLETE:
                case WagerValidationCodes.HAS_NO_VALUE:
                    // wager was updated but isn't complete yet, so no error message but still not valid
                    this.errorText = '';
                    this.isValid = false;
                    break;
                default:
                    this.isValid = false;
            }
        } else {
            this.total = '0';
            this.isValid = false;
            // Dismiss errors because no selections have been made to validate. The selections
            // may have been reset due to another change in the wager state.
            this._sendWagerNoValueEvent();
        }

        this._setBetModifier(wagerState);
        this._changeDetector.markForCheck();
    }

    private _setBetModifier(wagerState: WagerState) {
        switch (wagerState?.betNav?.modifier) {
            case enumBetModifier.BOX:
                this.displayModifier = BetsModifierDisplayValue.BOX;
                break;
            case enumBetModifier.KEY:
                this.displayModifier = BetsModifierDisplayValue.KEY;
                break;
            case enumBetModifier.KEY_BOX:
                this.displayModifier = BetsModifierDisplayValue.KEY_BOX;
                break;
            case enumBetModifier.POWER_BOX:
                this.displayModifier = BetsModifierDisplayValue.POWER_BOX;
                break;
            default:
                this.displayModifier = null;
                break;
        }
    }

    /**
     * Returns true if entries are selected in any leg. Also pulled from the F0001 branch.
     * Had to add a check to make sure each array was defined before checking length.
     *
     * @param wagerState
     */
    protected areEntriesSelected(wagerState: WagerState): boolean {
        if (wagerState && wagerState.bettingInterests) {
            return wagerState.bettingInterests.reduce((p, c) => p || !!c && c.length > 0, false);
        }
        return false;
    }

    protected shouldResetSubmittedWager(prevWagerState: WagerState, currentWagerState: WagerState) {
        return this.submittedWagerState && this.areEntriesSelected(currentWagerState);
    }

    protected toggleKeepPicks() {
        this.keepPicks = !this.keepPicks;
         this._saveKeepPicks();
        this._logKeepPicksClickEvent();
    }

    public finalizeSubmittingBet() {
        this.resetSubmittedBet();
    }

    private _saveKeepPicks() {
        localStorage.setItem(
            AbstractBetTotalComponent.KEEP_PICKS_FLAG_STORAGE_KEY,
            JSON.stringify(this.keepPicks)
        );
    }

    private _loadKeepPicks() {
        this.keepPicks =  JSON.parse(localStorage.getItem(
            AbstractBetTotalComponent.KEEP_PICKS_FLAG_STORAGE_KEY
        )) || false;
    }

    private resetSubmittedBet() {
        if (this.submittedWagerState) {
            this.resetTicket();
            this.handleValidation(this.wagerState);
        }
    }

    private checkValidCondWagerType(name: IPoolType, runners: Entry[][]): boolean {
        let flag: boolean = false;
        if (name && runners) {
            let condWagerTypes: WAGER_CONDITIONS = null;
            if (this.condWagerConfig.showOdds.includes(name.Code.toUpperCase())) {
                condWagerTypes = WAGER_CONDITIONS.ODDS;
            } else if (this.condWagerConfig.showProbPayout.includes(name.Code.toUpperCase())) {
                condWagerTypes = WAGER_CONDITIONS.PAYOUT;
            }
            if (!!name && condWagerTypes !== null) {
                for (let i = 0; i < runners.length; i++) {
                    const firstRunner = !!runners[i][0] ? runners[i][0].ProgramNumberCoupled : null;
                    if (!!firstRunner && runners[i].length === runners[i].filter(runner => runner.ProgramNumberCoupled === firstRunner).length) {
                        flag = true;
                    } else {
                        return false;
                    }
                }
            }
        }
        return flag;
    }

    /******* SENDING EVENTS *******/
    protected _sendWagerValidEvent() {
        this.outboundEvents.emit({
            type: WagerEventTypeEnum.WAGER_VALID,
        });
    }

    protected _sendWagerInvalidEvent(error: IBetError) {
        this.outboundEvents.emit({
            type: WagerEventTypeEnum.WAGER_INVALID,
            message: error.errorString,
            data: error
        });
    }

    protected _sendWagerProcessingEvent() {
        this.outboundEvents.emit({
            type: WagerEventTypeEnum.WAGER_SUBMISSION_PROCESSING,
            invalidatedBy: [WagerEventTypeEnum.WAGER_SUBMISSION_SUCCESS, WagerEventTypeEnum.WAGER_SUBMISSION_ERROR]
        });
    }

    protected _sendWagerSuccessEvent(action: BetActionsEnum) {
        this.outboundEvents.emit({
            type: WagerEventTypeEnum.WAGER_SUBMISSION_SUCCESS,
            message: action === BetActionsEnum.REPEAT_BET ? 'quick-bet-success-header' : 'classic-and-tv-success-header'
        });
    }

    protected _sendWagerFailureEvent(betId?: string, action?: BetActionsEnum) {
        const error = this._betSlipErrorService.getError(betId);
        this.outboundEvents.emit({
            type: action === BetActionsEnum.REPEAT_BET ? WagerEventTypeEnum.REPEAT_WAGER_SUBMISSION_ERROR : WagerEventTypeEnum.WAGER_SUBMISSION_ERROR,
            message: error ? error.errorString : 'Sorry, your bet cannot be placed.',
            data: error
        });
    }

    protected _sendUnknownWagerFailureEvent(error: any) {
        this.outboundEvents.emit({
            type: WagerEventTypeEnum.WAGER_SUBMISSION_ERROR,
            message: 'Unknown Error Occurred.',
            data: error
        });
    }

    protected _sendSaveProcessingEvent() {
        this.outboundEvents.emit({
            type: WagerEventTypeEnum.WAGER_SAVE_PROCESSING,
            invalidatedBy: [WagerEventTypeEnum.WAGER_SAVE_SUCCESS, WagerEventTypeEnum.WAGER_SAVE_ERROR]
        });
    }

    protected _sendSaveSuccessEvent() {
        this.outboundEvents.emit({
            type: WagerEventTypeEnum.WAGER_SAVE_SUCCESS
        });
    }

    protected _sendSaveFailureEvent(error: any) {
        this.outboundEvents.emit({
            type: WagerEventTypeEnum.WAGER_SAVE_ERROR,
            message: 'Unable to save wager.',
            data: error,
        });
    }

    protected _sendWagerNoValueEvent() {
        this.outboundEvents.emit({
            type: WagerEventTypeEnum.WAGER_NO_VALUE,
        });
    }
}
