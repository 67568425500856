import { Injectable } from '@angular/core';
import { TournamentSelectionViewEnum } from '../tournament-session-view.enum';
import { TodaysRacesBusinessService } from 'app/shared/program/services/todays-races.business.service';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common';
import { take } from 'rxjs/internal/operators/take';
import { map } from 'rxjs/internal/operators/map';
import { enumRaceStatus } from '@cdux/ng-common';
import {  filter } from 'rxjs/operators';

@Injectable({providedIn: 'root'}) 
export class TournamentsRedirectService   {

    public tournamentSelectionView: TournamentSelectionViewEnum = TournamentSelectionViewEnum.DEFAULT;
    private history: string[] = [];

    constructor(
        private _todaysRacesBusinessService: TodaysRacesBusinessService,
        private _location: Location,
        private _router: Router
    ) {
        this._location.go;
        this._router.events.pipe(filter((event) => event instanceof NavigationStart), take(1)).subscribe((event: NavigationStart) => {
            if (event.url.startsWith("/betpad") || event.url.startsWith("selection/view/betpad")) {
                this.tournamentSelectionView = TournamentSelectionViewEnum.BETPAD
            } else if (event.url.startsWith("/video/live") === true) {
                this.tournamentSelectionView = TournamentSelectionViewEnum.VIDEO
            } else {
                this.tournamentSelectionView = TournamentSelectionViewEnum.DEFAULT;
            }
        });


        this._router.events.pipe(
            filter(event => event instanceof NavigationEnd && event.url !== '/login')
        ).subscribe(
            (e: NavigationEnd) => {
                this.history.push(e.urlAfterRedirects);
            }
        );
    }

    public goToTournamentProgramPage(tournamentId: number, broadcasted = false) {
        switch (this.tournamentSelectionView) {
            case TournamentSelectionViewEnum.VIDEO: 
                this._location.back();
                break;
            case TournamentSelectionViewEnum.BETPAD:
                this._router.navigate(['/betpad','races']).then((res) => {
                    window.location.reload();
                });
                break;
            case null:
                this._router.navigate(['/']);
                break;
            default:
                this._todaysRacesBusinessService.getTodaysActiveTracks(false, true)
                    .pipe(
                        take(1),
                        map(track => track.generalTracks[0])
                    )
                    .subscribe((track) => {
                        try {
                            if (track.races[track.currentRaceIndex].status === enumRaceStatus.OFFICIAL
                                || track.races[track.currentRaceIndex].status === enumRaceStatus.CLOSED) {
                                this._router.navigate(['/tournaments', tournamentId ,'details'], { replaceUrl: true });
                                return;
                            }
                            const url = ['/program',track.name, track.brisCode, track.type, track.races[track.currentRaceIndex].raceNumber];
                            this._router.navigate(url, { replaceUrl: true}).then((_) => {
                                if (broadcasted) {
                                    window.location.reload();
                                }
                            });
                        } catch (e) {
                            this._router.navigate(['/'], { replaceUrl: true });
                        }
                    });
        }
    }

    public goToTodaysTrackPage() {
        switch (this.tournamentSelectionView) {
            case TournamentSelectionViewEnum.VIDEO: 
                this._location.back();
                break;
            case TournamentSelectionViewEnum.BETPAD:
                window.location.href='betpad/races';
                break;
            default:
                this._router.navigate(['/']);
        }
    }

    public returnToPreviosScreen() {
        if (this.history.length > 1) {
            this._router.navigateByUrl(this.history[this.historyCount() - 2]);
        } else {
            this._router.navigateByUrl('/')
        }
    }

    public historyCount(){
        return this.history.length;
    }

    public addToHistory(item: string) {
        this.history.push(item);
    }
}