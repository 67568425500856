import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, from, of, Subject, Subscription, throwError, zip } from 'rxjs';
import {
    map,
    reduce,
    concatMap,
    tap,
    delay,
    take,
    takeUntil,
    filter,
    distinctUntilChanged,
    catchError,
    flatMap
} from 'rxjs/operators';

import {
    Bet,
    BetCalculatorBusinessService,
    CduxStorage,
    ConfigurationDataService,
    enumConfigurationStacks,
    FeatureToggleDataService,
    IBet,
    TranslateService,
    WagerService,
    enumFeatureToggle,
    JwtSessionService,
    CduxObjectUtil,
    UserEventEnum,
    IBetSlipResponse,
} from '@cdux/ng-common';
import { LoadingService, LoadingDotsComponent, ToastService } from '@cdux/ng-fragments';

import { CduxStorageService } from '@cdux/ng-platform/web'
import { BET_ERROR_CODES } from '../../enums/bet-error-codes.enum';
import { BetSlipBusinessService } from '../../services/bet-slip.business.service';
import { BetSlipErrorsService } from '../../services/bet-slip-errors.service';
import { BetShareBusinessService } from '../../services/betshare.business.service';
import { IUserInfo } from '@cdux/ng-core';
import { SsnCollectionService } from '../../../ssn-collection/services/ssn-collection.service';
import { ConditionalWageringBusinessService } from '../../services/conditional-wagering.business.service';
import { BetsBusinessService } from '../../services/bets.business.service';
import { enumProgramViews } from 'app/shared/program/enums/program-views.enum';
import { MyBetsBusinessService } from 'app/shared/bets/services/my-bets.business.service';

@Component({
    selector: 'cdux-bet-slip',
    templateUrl: './bet-slip.component.html',
    styleUrls: ['./bet-slip.component.scss']
})
export class BetSlipComponent implements OnInit, OnDestroy {
    private static readonly BET_BATCH_SIZE_CONFIG_KEY = 'betslip_batch_size';
    private static BET_BATCH_SIZE: number = 15;

    /**
     * Emissions from this should be responded to by the parent scrolling the bet slip to the top.
     *
     * @type {EventEmitter<undefined>}
     */
    @Output() resetScroll: EventEmitter<undefined> = new EventEmitter<undefined>();

    /**
     * Emits when there is a size down of the bet slip.
     *
     * @type {EventEmitter<undefined>}
     */
    @Output() sizeChange: EventEmitter<number | undefined> = new EventEmitter<undefined>();

    /**
     * Number of bets in the bet slip.
     */
    @Output() betCount: EventEmitter<number> = new EventEmitter<number>();
    private _submittableBetCount: number = 0;
    private _displayableBetCount: number = 0;

    /**
     * Total cost of the bets in the bet slip.
     */
    @Output() totalAmount: EventEmitter<string> = new EventEmitter<string>();
    private _totalAmount: string = '0';

    /**
     * Are the bets marked as valid?
     */
    @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
    private _valid: boolean = false;

    @Output() onInitialize: EventEmitter<undefined> = new EventEmitter<undefined>();

    /*
     * Notify parent when submitAll starts/finishes
     */
    @Output() submitAllInProgress: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Output() onChangeDetection: EventEmitter<undefined> = new EventEmitter<undefined>();

    @Input() betIdToSubmit: string;

    @Input() betShareId: string;

    private _isBetPad: boolean = false;
    private _programView: enumProgramViews;
    @Input()
    public set programView(value: enumProgramViews) {
        this._programView = value;
        this._isBetPad = (value === enumProgramViews.BETPAD);
    }
    public get programView(): enumProgramViews {
        return this._programView;
    }

    @Input() showSaddleCloths: boolean;

    public allBets: IBet[] = [];
    public bets: Bet[] = [];
    public submitSuccess = false;
    public requireSSN = false;
    public showBets = false;
    public betShareEnabled = false;
    public isConditionalWagerToggleOn = false;
    public betSlipInitialized = false;
    public loadingDotsComponent = LoadingDotsComponent;
    public mtpConfig: number = 0;
    public betToShowSsnCollection: string;
    public splitBetFT: boolean = false;

    public BET_PAD_FUNDING_FEATURE_ENABLED = false;

    private _subscriptions: Subscription[] = [];
    private _tempBetShares: Bet[] = [];
    private _singleSubmissionsInProgress: string[] = [];

    public set submitting(val: boolean) {
        this._submitting = val;
        this._blockUpdate.next(val || !!this.betToShowSsnCollection);
    }
    public get submitting() {
        return this._submitting;
    }

    /**
     * Observes conditions under which the bet slip should NOT rebuild its bets.
     *
     * @type {BehaviorSubject<boolean>}
     */
    private _blockUpdate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private _submitting = false;

    // Subject used to trigger subscription cleanup
    private _destroy: Subject<boolean> = new Subject();

    /**
     * Constructor
     *
     * @param {BetCalculatorBusinessService} _betCalculatorBusinessService
     * @param {BetSlipErrorsService} _betSlipErrorsService
     * @param {BetSlipBusinessService} _betSlipService
     * @param {BetShareBusinessService} _betShareService
     * @param {ChangeDetectorRef} _changeDetector
     * @param {CduxStorageService} _cduxStorageService
     * @param {JwtSessionService} _sessionService
     * @param {ToastService} _toastService
     * @param {TranslateService} _translateService
     * @param {WagerService} _wagerService
     * @param {LoadingService} _loadingService
     * @param {FeatureToggleDataService} _featureToggleService
     * @param {SsnCollectionService} _ssnCollectionService
     */
    constructor(
        private _betCalculatorBusinessService: BetCalculatorBusinessService,
        private _betSlipErrorsService: BetSlipErrorsService,
        private _betSlipService: BetSlipBusinessService,
        private _betShareService: BetShareBusinessService,
        private _cduxStorageService: CduxStorageService,
        private _changeDetector: ChangeDetectorRef,
        private _configService: ConfigurationDataService,
        private _sessionService: JwtSessionService,
        private _toastService: ToastService,
        private _translateService: TranslateService,
        private _wagerService: WagerService,
        private _loadingService: LoadingService,
        private _featureToggleService: FeatureToggleDataService,
        private _ssnCollectionService: SsnCollectionService,
        private _conditionalWagerService: ConditionalWageringBusinessService,
        private _betsBusinessService: BetsBusinessService,
        private _myBetsService: MyBetsBusinessService
    ) {}

    ngOnInit() {
        const condWagerObs = this._featureToggleService
            .watchFeatureToggle(enumFeatureToggle.CONDITIONAL_WAGERING).pipe(
                flatMap(toggledOn => {
                    if (toggledOn) {
                       return this._conditionalWagerService.getCondWagerConfig().pipe(
                           map(config => {
                               if (!!config) {
                                   this.isConditionalWagerToggleOn = true;
                               }
                           })
                       );
                    }
                    return of(null);
                }),
                catchError(err => of( null))
            );

        const betShareConfigObs = this._featureToggleService.watchFeatureToggle(enumFeatureToggle.BETSHARE).pipe(
                flatMap(toggledOn => {
                    if (toggledOn) {
                        return this._betShareService.getBetShareConfig().pipe(map(config => {
                            this.mtpConfig = config.minutesToPostRestriction;
                            this.betShareEnabled = this._betShareService.unrestrictedState();
                        }));
                    }
                    return of(null);
                }),
                catchError(err => of( null))
            );

        if (this._isBetPad) {
            this._featureToggleService.watchFeatureToggle(enumFeatureToggle.BETPAD_FUNDING).pipe(
                takeUntil(this._destroy)
            ).subscribe((status) => {
                this.BET_PAD_FUNDING_FEATURE_ENABLED = status;
            });
        }

        this._loadingService.register('betSlipContainer');
        zip(betShareConfigObs, condWagerObs).pipe(take(1)).subscribe(result => {
            this._loadingService.resolve('betSlipContainer', 0, 'success');
            this.betSlipInitialized = true;
            this.onInitialize.emit();
        });

        // fetch bets to be copied
        if (this._betSlipService.betsToAdd.length > 0) {
            setTimeout(() => {
                this._betSlipService.betsToAdd.forEach((bet) => {
                    this._cduxStorageService.store(bet);
                });
            }, 500);
        }
        // Initial fetch
        this._processAllBets();

        // Subscribe to changes
        this._subscriptions = [
            // When the list of bets changes, update the bet slip.
            this._cduxStorageService.observe(Bet.DB, null, Bet.VERSION).subscribe((e: IBet[]) => {
                this.allBets = e;
                this._processBetsFromStore(e);
                this.showBets = this.shouldShowBets();
                this.onChangeDetection.emit();
            }),
            // If the bet changes, then we may be editing, so update the current bet slip and omit the one
            // being edited, if applicable.
            // I need to not let this trigger in the middle of submission,
            // otherwise it will interrupt the animation or the SSN collection.
            combineLatest([
                this._betSlipService.onBetChange,
                this._blockUpdate
            ]).pipe(
                filter(([b, s]) => !s),
                map(([b, _]) => b)
            )
            .subscribe(() => {
                this._processBetsFromStore(this.allBets);
                this.showBets = this.shouldShowBets();
                this._changeDetector.detectChanges();
                this.sizeChange.emit();
            }),
            // On login, a refetch should occur.
            this._sessionService.onAuthenticationChange.pipe(
                distinctUntilChanged()
            ).subscribe((isLoggedIn) => {
                if (isLoggedIn) {
                    this._cduxStorageService.fetch({db: Bet.DB, version: Bet.VERSION}).then((e) => {
                        this._processBetsFromStore(e);
                        this.showBets = this.shouldShowBets();
                    });
                }
            })
        ];

        this.splitBetFT = this._featureToggleService.isFeatureToggleOn('SPLIT_BET_BUTTON');

        this._configService.getConfiguration(
            enumConfigurationStacks.TUX, BetSlipComponent.BET_BATCH_SIZE_CONFIG_KEY
        ).pipe(
            take(1)
        ).subscribe(({[ BetSlipComponent.BET_BATCH_SIZE_CONFIG_KEY ]: batchSize}) =>
            !isNaN(+batchSize) && (BetSlipComponent.BET_BATCH_SIZE = +batchSize)
        );
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach((e: Subscription) => {
            if (!e.closed) {
                e.unsubscribe();
            }
        });
        this._subscriptions = [];
        this._betSlipService.betsToAdd = [];

        this._destroy.next();
        this._destroy.complete();
    }

    /**
     * Determine whether the bets should be shown.
     *
     * @returns {boolean}
     */
    public shouldShowBets() {
        return this._sessionService.isLoggedIn() && (this._displayableBetCount > 0 || this._tempBetShares.length > 0);
    }

    /**
     * Submit all bets.
     */
    public submitAll() {
        // Prevent multiple submissions for the same batch of bets
        if (this.submitting || this.requireSSN || this.betToShowSsnCollection) {
            return;
        } else {
            this.submitting = true;
            this.submitAllInProgress.emit(this.submitting);
        }

        // short for IBetsChanged
        const ibetcha = this.bets.filter((bet) => {
            // prevent submitting of successful bet-share wager
            // prevent submitting wager that was submitted individually and not finished processing
            if (bet.isSubmittedWager || this._singleSubmissionsInProgress.includes(bet.id)) {
                return false;
            }
            const error = this._betSlipErrorsService.getError(bet.id) || {} as any;
            const isGoodBet = !error.isPermanent;

            if (isGoodBet) {
                this._betSlipErrorsService.removeError(bet.id);
            }

            return isGoodBet;
        }).map((bet) => bet.getSubmittableWager());

        // Retrieve User Info
        const userInfo = this._sessionService.getUserInfo();

        let betibetcha: IBet[] = [];
        const betshareibetcha: IBet[] = ibetcha.filter(b => b.betShare);
        const requestSSN = userInfo.cssdLength < 9 && betshareibetcha.length > 0;
        if (requestSSN) {
            betibetcha = ibetcha.filter(b => !b.betShare);
        } else {
            betibetcha = ibetcha;
        }

        this._placeWagers(betibetcha, userInfo, false)
            .pipe(
                concatMap(([badBets, goodBets]) => {
                    // If we require an SSN update, do so here.
                    if (requestSSN) {
                        this.submitSuccess = false;
                        this.requireSSN = true;
                        return this._ssnCollectionService.ssnUpdated
                            .pipe(
                                take(1),
                                concatMap((ssnUpdated) => {
                                    if (ssnUpdated === null) { // Cancellation emits null.
                                        this.requireSSN = false;
                                    } else if (ssnUpdated) {
                                        // Need To close the SSN component as SSN is updated
                                        this.requireSSN = false;
                                        return this._placeWagers(betshareibetcha, userInfo, true).pipe(
                                            map(([badBetShareBets, goodBetShareBets]) => {
                                                badBets = [].concat(badBets, badBetShareBets);
                                                goodBets = [].concat(goodBets, goodBetShareBets);
                                                return [badBets, goodBets];
                                            })
                                        );
                                    } else {
                                        this.requireSSN = false;
                                        betshareibetcha.forEach(betShare => {
                                            this._betSlipErrorsService.setError(betShare.wagerId, BET_ERROR_CODES.BS_SSN);
                                        });
                                    }
                                    return of([badBets, goodBets]);
                                })
                            );
                    } else {
                        return of([badBets, goodBets]);
                    }
                }),
                concatMap(([badBets, goodBets]) => {
                    // push betshare wagers to temp array to display share option in bet-slip
                    // Only drop the db if the split bet ft is on. The new Bet Slip container uses watchDB which doesn't
                    // appreciate the db you are watching being dropped
                    if (goodBets.length === this.bets.length && !this.splitBetFT) {
                        return from(this._cduxStorageService.dropDB(Bet.DB));
                    } else if (goodBets.length) {
                        const goodBetsQueries: CduxStorage.FetchQuery[] = goodBets.map((e) => ({
                            db: Bet.DB,
                            _id: e.wagerId
                        }));
                        return from(this._cduxStorageService.destroy(goodBetsQueries));
                    } else {
                        return of([badBets, goodBets]);
                    }
                }),
                tap(() => this._changeDetector.detectChanges()),
                // In the event of a failure in the pipeline, show the toast and return an empty array.
                catchError((err) => {
                    // Expired JWT error occurs when logged in user places wager after
                    // JWT expires but before being logged out. The subsequent error
                    // message is not returned on the Bets array from the BetSlip call
                    // so we need to check for it here and the BetsBusinessService.
                    // See PR https://github.com/twinspires/cdux-ng/pull/1489.
                    const requestErrorMessage: string = err.data ? CduxObjectUtil.deepGet(err, 'data.error.Error.Description') : err;
                    if (requestErrorMessage === BET_ERROR_CODES.EXPIRED_JWT) {
                        this._betsBusinessService.handleExpiredJwtError();
                    } else {
                        this._toastService.cduxWarning(this._translateService.translate(BET_ERROR_CODES.BETS_FAILED, 'wager-errors'));
                    }
                    // Get rid of associated submission animations
                    return of([]);
                })
            )
            // Turn off any running submission animations.
            .subscribe(() => {
                this.submitting = false;
                this.submitSuccess = false;
                this.submitAllInProgress.emit(this.submitting);
            })
    }

    /**
     * stores/destroys a temporary bet share wagers in bet-slip showing the share options after submission
     * @param {{bet: Bet; store: boolean, callback?: any}} betShareInfo
     */
    public updateTempBetShares(betShareInfo: {bet: Bet, store: boolean, callback?: any}): void {
        if (betShareInfo.store) {
            this._tempBetShares.push(betShareInfo.bet);
        } else {
            const index = this._tempBetShares.indexOf(betShareInfo.bet);
            if (index > -1) {
                this._betSlipErrorsService.removeError(betShareInfo.bet.id);
                this._tempBetShares.splice(index, 1);
                this._processAllBets();
            }
        }
        if (betShareInfo.callback) {
            betShareInfo.callback();
        }
    }

    /**
     * Maintains the ID of the bet that should show SSN collection, if any.
     *
     * @param {{betId: string, display: boolean}} ssnCollectionInfo
     */
    public displaySsnCollection(ssnCollectionInfo: {betId: string, display: boolean}) {
        if (ssnCollectionInfo.betId && ssnCollectionInfo.display) {
            this.betToShowSsnCollection = ssnCollectionInfo.betId;
        } else {
            this.betToShowSsnCollection = undefined;
        }
        this._blockUpdate.next(this._submitting || !!this.betToShowSsnCollection);
        this._changeDetector.detectChanges();
    }

    /*
     * fetches all bets from DB and processes for display
     */
    private _processAllBets(): void {
        this._cduxStorageService.fetch({db: Bet.DB, version: Bet.VERSION}).then((e: IBet[]) => {
            this.allBets = e;
            this._processBetsFromStore(e, true);
            this.showBets = this.shouldShowBets();
            this.onChangeDetection.emit();
        });
    }

    /**
     * After removing from the data store, these things are technically not bets, but are bet-like. We do not have access
     * to the instance methods on them and as such, we need to create bet objects for them.
     * @param bets - betlikes to create from
     * @param {boolean} resetBetShare - clears all betShare related fields, optional
     */
    private _processBetsFromStore(bets: any, resetCustomWagers?: boolean) {
        bets = this._preprocessBets(bets);
        if (resetCustomWagers) {
            this._resetCustomWagerType(bets);
        }
        bets = bets.concat(this._tempBetShares);
        this.bets = this._sortBets(bets);
        this._changeDetector.detectChanges();

        const betsToDisplay = this.bets.filter(bet => !bet.isSubmittedWager || (bet.betShare && bet.isSubmittedWager));
        // BET COUNT
        const submittableBetCount = betsToDisplay.filter(b => !b.isSubmittedWager).length;
        // We have to keep submitted bet shares around, so the number to display is different from
        // submittable bets.
        const displayableBetCount = betsToDisplay.length;

        if (displayableBetCount !== this._displayableBetCount || submittableBetCount !== this._submittableBetCount) {
            this._displayableBetCount = displayableBetCount;
            this._submittableBetCount = submittableBetCount;
            this.betCount.emit(submittableBetCount);
            this.sizeChange.emit();
        }

        // TOTAL AMOUNT
        const total = betsToDisplay
            .filter(b => !b.isSubmittedWager)
            .map(e => this._betCalculatorBusinessService.calculate(e))
            .reduce((acc, e) => acc + e, 0)
            .toString();

        if (total !== this._totalAmount) {
            this._totalAmount = total;
            this.totalAmount.emit(this._totalAmount);
        }

        // VALIDITY
        const isValid = !this._betSlipErrorsService.hasErrors();

        if (isValid !== this._valid) {
            this._valid = isValid;
            this.valid.emit(this._valid);
        }
    }

    /**
     * Filters out bets to just what should show in the bet slip.
     *
     * @param {any[]} bets
     * @returns {Bet[]}
     * @private
     */
    private _preprocessBets(bets: any[]): Bet[] {
        const userName = this._sessionService.getUserInfo().username;
        return bets
            .map((e) => Bet.fromBetlike(e))
            .filter((e: Bet) =>
                e.userName === userName
                    && e.id !== this._betSlipService.currentBet.id
                    && e.showInBetSlip
                && !e.isQuickBet);
    }

    /**
     * Resets betshare settings, used when betslip initially opens
     * @param {Bet[]} bets
     * @private
     */
    private _resetCustomWagerType(bets: Bet[]) {
        bets.forEach((bet, i) => {
            if (bet.betShare && !bet.isSubmittedWager && (bet.id !== this.betIdToSubmit && bet.id !== this.betShareId)) {
                this._betShareService.resetBetShare(bet);
                // SSN required fields should not be shown on restart of the bet slip.
                const error = this._betSlipErrorsService.getError(bet.id);
                if (error && error.errorCode === BET_ERROR_CODES.BS_SSN) {
                    this._betSlipErrorsService.removeError(bet.id);
                }
                this._cduxStorageService.store(bet);
            } else if (bet.conditional && bet.id !== this.betIdToSubmit && i < bets.length - 1) {
                /**
                 * TODO?: This causes conditional wagering data to be stripped from the Bet.
                 * It's not obvious when copying a bet in the betslip, because
                 * that seems to bypass reading from the datastore. It is clear
                 * when trying to copy a wager from transaction history, which
                 * reaches the betslip through the datastore. It can also be
                 * seen when refreshing TUX with CWs in the betslip.
                 * For DE16841 fix, we avoid to strip the conditional info
                 * (conditional, conditionalMtp, conditionalOdds, conditionalProbablePayout)
                 * on the newest conditional wager just added from Advanced Options bet.
                 */
                this._conditionalWagerService.clearConditionalWager(bet);
                this._cduxStorageService.store(bet);
            }
        });
    }

    /**
     * Sorts the bets.
     *
     * @param {Bet[]} bets
     * @returns {Bet[]}
     * @private
     */
    private _sortBets(bets: Bet[]): Bet[] {
        bets.sort((a, b) => {
            const ida = a.id.split('--');
            const idb = b.id.split('--');

            for (let i = 0; i < idb.length; i++) {
                if (ida[i] === idb[i]) {
                    continue;
                }

                if (ida[i] > idb[i]) {
                    return -1;
                }

                if (ida[i] < idb[i]) {
                    return 1;
                }
            }
        });
        return bets;
    }

    private _batchWagers(bets: IBet[], batchSize = BetSlipComponent.BET_BATCH_SIZE): IBet[][] {
        return bets.reduce((batches, bet) => {
            if (batches.length === 0 || batches[batches.length - 1].length >= batchSize) {
                batches.push([ bet ]); // begin batch
            } else { // push bet to the current batch
                batches[batches.length - 1].push(bet);
            }
            return batches;
        }, <IBet[][]> []);
    }

    private _placeWagers(ibetcha: IBet[],
                         userInfo: IUserInfo,
                         suppressCollapseAnimation: boolean = false) {
        if (ibetcha.length === 0) {
            return of([[], []])
                .pipe(delay(2500));
        }
        const betShareBets = [];
        ibetcha.forEach(bet => {
            if (bet.betShare) {
                betShareBets.push(bet);
            }
        });

        return from(this._batchWagers(ibetcha))
            .pipe(
                concatMap((batch) =>
                    this._wagerService.placeWagers(batch, userInfo)
                ),
                reduce((unbatched, batch) => (<IBetSlipResponse> {
                    accountBalance: batch.accountBalance,
                    bets: unbatched.bets.concat(batch.bets)
                }), (<IBetSlipResponse> {
                    accountBalance: 0,
                    bets: []
                })),
                catchError((err) => {
                    ibetcha.forEach((b) => {
                        // Look at the error object that's returned for a description
                        const requestErrorMessage: string = err.data ? CduxObjectUtil.deepGet(err, 'data.error.Error.Description') : err;

                        if (this._wagerService.isGeolocationRequired(b, userInfo)) {
                            this._betSlipErrorsService.setError(b.wagerId, BET_ERROR_CODES.GEOLOCATION_REQUIRED);

                        // Refer to lines 372 - 376 for why this is necessary.
                        } else if (requestErrorMessage === BET_ERROR_CODES.EXPIRED_JWT) {
                            this._betsBusinessService.handleExpiredJwtError();
                        }
                    });
                    // Log the failed attempt to a tool for marketing
                    this._betsBusinessService.logWagerEvent(UserEventEnum.BET_FAILED, ibetcha);
                    // rethrow the error downstream
                    return throwError(err);
                }),
                tap((betSlipReponse: IBetSlipResponse) => this._betsBusinessService.logWagerEvent(UserEventEnum.BET_COMPLETE, betSlipReponse.bets, betSlipReponse.accountBalance)),
                // Map the bets to be split between bad and good bets.
                map((betSlipReponse: IBetSlipResponse) => [betSlipReponse.bets.filter(b => !b.success), betSlipReponse.bets.filter(b => b.success)]),
                // Decision branch between how to behave if there are good bets, or not.
                concatMap(([badBets, goodBets]) => {

                    badBets.forEach(bet => {
                        betShareBets.forEach(betShare => {
                            if (bet.wagerId === betShare.id && (bet.message === BET_ERROR_CODES.BETSHARE_MTP_RESTRICTION.replace('%s', this.mtpConfig.toString()))) {
                                betShare.betShare = false;
                                bet.message = BET_ERROR_CODES.BETSHARE_MTP_RESTRICTION.toString();
                                // show BETSHARE MTP RESTRICTION error by making betshare false as there are other error messages for betshare
                                bet.betShare = false;
                            }
                        });

                        // IF we're in BetPad and BET_PAD_FUNDING is toggled off,
                        // don't show "Deposit Now" link for INSUFFICIENT_FUND error:
                        if (bet.message === BET_ERROR_CODES.INSUFFICIENT_FUNDS
                            && this._isBetPad
                            && !this.BET_PAD_FUNDING_FEATURE_ENABLED
                        ) {
                            bet.message = BET_ERROR_CODES.INSUFFICIENT_FUNDS_NODEPOSIT.toString();
                            this._betSlipErrorsService.setError(bet.wagerId, bet.message);
                        } else {
                            this._betSlipErrorsService.setError(bet.wagerId, bet.message, bet.betShare);
                        }
                    });

                    if (goodBets.length) {
                        // Start the observable with the information of if all the bets were successful
                        // for use further down the chain.
                        return of([badBets, goodBets])
                            .pipe(
                                // Scroll to the top of the screen.
                                tap(() => this.resetScroll.emit()),
                                // Finish the submitting animation.
                                delay(suppressCollapseAnimation ? 0 : 1500),
                                tap(() => {
                                    this.submitSuccess = true;
                                    this.requireSSN = false;
                                }),
                                delay(2500),
                                tap(() => {
                                    betShareBets.forEach(betShare => {
                                        goodBets.forEach(goodBet => {
                                            if (goodBet.wagerId === betShare.id) {
                                                betShare.isSubmittedWager = true;
                                                betShare.betShareId = goodBet.message;
                                                this._tempBetShares.push(Bet.fromBetlike(betShare));
                                            }
                                        });
                                    });
                                })
                            );
                    } else {
                        // Continue with the bad bets.
                        return of([badBets, goodBets]);
                    }
                }),
                tap(([badBets, goodBets]) => {
                    if (goodBets && goodBets.length) {
                        this._myBetsService.refreshMyBetsCache();
                    }
                }),
            );
    }

    public toggleSingleSubmission(wagerObj: {betId: string, submitting: boolean}) {
        if (wagerObj.submitting) {
            this._singleSubmissionsInProgress.push(wagerObj.betId);
        } else {
            this._singleSubmissionsInProgress = this._singleSubmissionsInProgress.filter(id => id !== wagerObj.betId);
        }
    }

    public betSlipTrackBy(index: number, item: Bet) {
        return item.id;
    }
}
